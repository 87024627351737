// NPM Packages
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
} from 'semantic-ui-react';

// Custom Modules
import Loader from './../../Loader';
import User from './User';

// Styles
import './index.css';

function ReadUser(props) {
  // Params
  const params = useParams();

  // Hooks
  useEffect(() => {
    if (
      props.data.token
      && params.userId
      && !props.data.fetching
      && !props.data.fetched
    ) {
      props.handlers.readUser(props.data.token, params.userId);
    }
  }, [
    params.userId,
    props.token,
    props.data.token,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
    props.handlers.readUser,
  ]);

  function renderLoader() {
    return (
      <Loader texts={props.texts} />
    );
  }

  function renderLoaderOrUser() {
    let result;

    if (props.data.user && props.data.user) {
      result = renderUser(props.data.user);
    }
    else {
      result = renderLoader();
    }

    return result;
  }

  function renderUser(user) {
    return (
      <User
        data={{
          fetching: props.data.fetching,
          token: props.data.token,
          user,
        }}
        handlers={{
          openPopupUpdateUser: props.handlers.openPopupUpdateUser,
        }}
        texts={props.texts}
      />
    );
  }

  return (
    <div className="ReadUser">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Container className="LoaderOrUser">
              { renderLoaderOrUser() }
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ReadUser;
