// NPM Packages

// Styles
import './index.css';

// Constants

function BookMe(props) {
  return (
    <div className="BookMe">
    </div>
  );
}

export default BookMe;
