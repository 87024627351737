// NPM Packages
import {
  Button,
  Icon,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';

// Custom Modules
import { DeleteUser } from './../../../../containers';

// Styles
import './index.css';

// Constants
const BUTTON_UPDATE_USER_ICON = true;
const BUTTON_UPDATE_USER_ICON_NAME = 'edit';
const BUTTON_UPDATE_USER_SIZE = 'mini';
const TEXT_ALIGN_CENTER = 'center';
const USER_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
const USER_IS_ADMIN_COLOR_FALSE = 'red';
const USER_IS_ADMIN_COLOR_TRUE = 'green';
const USER_IS_ADMIN_NAME_FALSE = 'x';
const USER_IS_ADMIN_NAME_TRUE = 'check';
const USER_IS_ADMIN_SIZE = 'large';
const USER_IS_CONNECTED_COLOR_FALSE = 'red';
const USER_IS_CONNECTED_COLOR_TRUE = 'green';
const USER_IS_CONNECTED_NAME_FALSE = 'x';
const USER_IS_CONNECTED_NAME_TRUE = 'check';
const USER_IS_CONNECTED_SIZE = 'large';
const USER_IS_SUBSCRIBED_COLOR_FALSE = 'red';
const USER_IS_SUBSCRIBED_COLOR_TRUE = 'green';
const USER_IS_SUBSCRIBED_NAME_FALSE = 'x';
const USER_IS_SUBSCRIBED_NAME_TRUE = 'check';
const USER_IS_SUBSCRIBED_SIZE = 'large';
const USER_IS_SUBSCRIPTION_ACTIVE_COLOR_FALSE = 'red';
const USER_IS_SUBSCRIPTION_ACTIVE_COLOR_TRUE = 'green';
const USER_IS_SUBSCRIPTION_ACTIVE_NAME_FALSE = 'x';
const USER_IS_SUBSCRIPTION_ACTIVE_NAME_TRUE = 'check';
const USER_IS_SUBSCRIPTION_ACTIVE_SIZE = 'large';

function User(props) {
  // Renderers
  function renderUserPasswordChangeAt(user) {
    return moment(user.passwordChangeAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserPasswordResetSentAt(user) {
    return moment(user.passwordResetSentAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserCurrentSignInAt(user) {
    return moment(user.currentSignInAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserLastSignInAt(user) {
    return moment(user.lastSignInAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserEmailConfirmedAt(user) {
    return moment(user.emailConfirmedAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserEmailConfirmationSentAt(user) {
    return moment(user.emailConfirmationSentAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserLockedAt(user) {
    return moment(user.lockedAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserSubscriptionExpiredAt(user) {
    return moment(user.subscriptionExpiredAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserCreatedAt(user) {
    return moment(user.createdAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserUpdatedAt(user) {
    return moment(user.updatedAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserIsAdmin(user) {
    let result;

    if (user.isAdmin) {
      result = (
        <Icon
          color={USER_IS_ADMIN_COLOR_TRUE}
          name={USER_IS_ADMIN_NAME_TRUE}
          size={USER_IS_ADMIN_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_ADMIN_COLOR_FALSE}
          name={USER_IS_ADMIN_NAME_FALSE}
          size={USER_IS_ADMIN_SIZE}
        />
      );
    }

    return result;
  }

  function renderUserIsConnected(user) {
    let result;

    if (user.isConnected) {
      result = (
        <Icon
          color={USER_IS_CONNECTED_COLOR_TRUE}
          name={USER_IS_CONNECTED_NAME_TRUE}
          size={USER_IS_CONNECTED_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_CONNECTED_COLOR_FALSE}
          name={USER_IS_CONNECTED_NAME_FALSE}
          size={USER_IS_CONNECTED_SIZE}
        />
      );
    }

    return result;
  }

  function renderUserIsSubscribed(user) {
    let result;

    if (user.isSubscribed) {
      result = (
        <Icon
          color={USER_IS_SUBSCRIBED_COLOR_TRUE}
          name={USER_IS_SUBSCRIBED_NAME_TRUE}
          size={USER_IS_SUBSCRIBED_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_SUBSCRIBED_COLOR_FALSE}
          name={USER_IS_SUBSCRIBED_NAME_FALSE}
          size={USER_IS_SUBSCRIBED_SIZE}
        />
      );
    }

    return result;
  }

  function renderUserIsSubscriptionActive(user) {
    let result;

    if (user.isSubscriptionActive) {
      result = (
        <Icon
          color={USER_IS_SUBSCRIPTION_ACTIVE_COLOR_TRUE}
          name={USER_IS_SUBSCRIPTION_ACTIVE_NAME_TRUE}
          size={USER_IS_SUBSCRIPTION_ACTIVE_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_SUBSCRIPTION_ACTIVE_COLOR_FALSE}
          name={USER_IS_SUBSCRIPTION_ACTIVE_NAME_FALSE}
          size={USER_IS_SUBSCRIPTION_ACTIVE_SIZE}
        />
      );
    }

    return result;
  }

  return (
      <Table className="User" definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2" textAlign={TEXT_ALIGN_CENTER}>
              <Button
                icon={BUTTON_UPDATE_USER_ICON}
                onClick={
                  () => props.handlers.openPopupUpdateUser(props.data.user)
                }
                secondary
                size={BUTTON_UPDATE_USER_SIZE}
              >
                <Icon name={BUTTON_UPDATE_USER_ICON_NAME} />
              </Button>
              <DeleteUser
                data={{
                  fetching: props.data.fetching,
                  token: props.data.token,
                  userId: props.data.user.id,
                }}
                texts={props.texts}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_ID_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.id }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_EMAIL_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.email }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_PASSWORD_CHANGE_COUNT_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.passwordChangeCount }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_PASSWORD_CHANGED_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.passwordChangeAt
               && renderUserPasswordChangeAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_PASSWORD_RESET_COUNT_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.passwordResetCount }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_PASSWORD_RESET_SENT_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.passwordResetSentAt
               && renderUserPasswordResetSentAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_SIGN_IN_COUNT_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.signInCount }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_CURRENT_SIGN_IN_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.currentSignInAt
               && renderUserCurrentSignInAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_LAST_SIGN_IN_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.lastSignInAt
               && renderUserLastSignInAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_CURRENT_SIGN_IN_IP_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.currentSignInIp }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_LAST_SIGN_IN_IP_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.lastSignInIp }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_EMAIL_CONFIRMED_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.emailConfirmedAt
               && renderUserEmailConfirmedAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_EMAIL_CONFIRMATION_SENT_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.emailConfirmationSentAt
               && renderUserEmailConfirmationSentAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_FAIL_ATTEMPTS_LABEL'] }
           </Table.Cell>
           <Table.Cell>
             { props.data.user.failedAttempts }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_LOCKED_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.lockedAt
               && renderUserLockedAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_IS_ADMIN_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             { renderUserIsAdmin(props.data.user) }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_IS_CONNECTED_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             { renderUserIsConnected(props.data.user) }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_IS_SUBSCRIBED_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             { renderUserIsSubscribed(props.data.user) }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_IS_SUBSCRIPTION_ACTIVE_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             { renderUserIsSubscriptionActive(props.data.user) }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_SUBSCRIPTION_EXPIRED_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.subscriptionExpiredAt
               && renderUserSubscriptionExpiredAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_CREATED_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.createdAt
               && renderUserCreatedAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
         <Table.Row>
           <Table.Cell>
             { props.texts['USERS_READ_USER_UPDATED_AT_LABEL'] }
           </Table.Cell>
           <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
             {
               props.data.user.updatedAt
               && renderUserUpdatedAt(props.data.user)
             }
           </Table.Cell>
         </Table.Row>
        </Table.Body>
        <Table.Footer>
        </Table.Footer>
      </Table>
  );
}

export default User;
