// Custom Modules
import {
  /*
   * Videos
   */
  // CRUD Operations
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAILURE,
  LIST_VIDEOS_REQUEST,
  LIST_VIDEOS_SUCCESS,
  LIST_VIDEOS_FAILURE,
  //READ_VIDEO_REQUEST,
  //READ_VIDEO_SUCCESS,
  //READ_VIDEO_FAILURE,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE,

  SELECT_VIDEO,

  /*
   * UI
   */
  OPEN_POPUP,
  CLOSE_POPUP,
  RESET_PAGE,
} from './../actionTypes';

// Helpers

// Data
const initialState = {
  activeId: null,
  ids: [],
  items: {},
  pagination: {},
  errors: [],
  fetching: false,
  fetched: false,
  notice: null,
};

const reducer = (state = initialState, action) => {
  let result;

  switch (action.type) {
    /*
     * Videos
     */
    // CRUD Operations
    case CREATE_VIDEO_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case CREATE_VIDEO_SUCCESS:
      result = {
        ...state,
        ids: [
          action.payload.video.id,
          ...state.ids,
        ],
        items: {
          ...state.items,
          [action.payload.video.id]: action.payload.video,
        },
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case CREATE_VIDEO_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;
    case LIST_VIDEOS_REQUEST:
      result = {
        ...state,
        ids: [],
        items: {},
        fetching: true,
        fetched: false,
      };

      break;
    case LIST_VIDEOS_SUCCESS:
      result = {
        ...state,
        ids: action.payload.videos.map(video => video.id),
        items: action.payload.videos.reduce((items, video) => {
          return {
            ...items,
            [video.id]: video,
          };
        }, {}),
        pagination: action.payload.pagination,
        fetching: false,
        fetched: true,
      };

      break;
    case LIST_VIDEOS_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case UPDATE_VIDEO_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case UPDATE_VIDEO_SUCCESS:
      result = {
        ...state,
        items: {
          ...state.items,
          [action.payload.video.id]: action.payload.video,
        },
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case UPDATE_VIDEO_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;
    case DELETE_VIDEO_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case DELETE_VIDEO_SUCCESS:
      const videoId = action.payload.video.id;
      const ids = state.ids.filter(id => id !== videoId);
      const items = state.items;
      delete items[videoId];

      result = {
        ...state,
        activeId: (state.activeId === videoId) ? null : state.activeId,
        ids,
        items,
        fetching: false,
        fetched: true,
      };

      break;
    case DELETE_VIDEO_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case SELECT_VIDEO:
      result = {
        ...state,
        activeId: action.payload,
      };

      break;

    /*
     * UI
     */
    case OPEN_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case CLOSE_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case RESET_PAGE:
      result = {
        ...initialState,
      };

      break;
    default:
      result = state;
  }

  return result;
};

export default reducer;
