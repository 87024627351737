// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  readUserRequest,
  selectUser,
} from './../store/actions';
import { UserPage } from './../pages';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {
      ...ownProps.data,

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * User
       */
      currentUser: state.user.current,

      /*
       * Users
       */
      user: state.users.selected,
      fetching: state.users.fetching,
      fetched: state.users.fetched,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Users
       */
      readUser: (token, userId) => readUserRequest(token, userId, dispatch),
      selectUser: (userId) => selectUser(userId, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
