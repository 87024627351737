// Custom Modules
import {
  /*
   * UI
   */
  OPEN_POPUP,
  CLOSE_POPUP,
} from './../actionTypes';

const initialState = {
  popupOpened: false,
};

const reducer = (state = initialState, action) => {
  let result;

  switch (action.type) {
    /*
     * UI
     */
    case OPEN_POPUP:
    result = {
      ...state,
      popupOpened: true,
    };

    break;
    case CLOSE_POPUP:
    result = {
      ...state,
      popupOpened: false,
    };

    break;
    default:
    result = state;
  }

  return result;
};

export default reducer;
