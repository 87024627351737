// NPM Packages
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Header,
  List,
} from 'semantic-ui-react'

// Styles
import './index.css';

// Constants
const CONTAINER_GRID_DIVIDED = true;
const CONTAINER_GRID_STACKABLE = true;
const MESSAGE_GRID_COLUMN_WIDTH = 8;
const MESSAGE_HEADER_AS = 'h4';
const MESSAGE_HEADER_INVERTED = true;
const PAGES_GRID_COLUMN_WIDTH = 4;
const PAGES_HEADER_AS = 'h4';
const PAGES_HEADER_INVERTED = true;
const PAGES_INVERTED = true;
const PAGES_LINK = true;
const PAGES_BOOK_ME_AS = 'a';
const PAGES_BOOK_ME_ID = 'book-me';
const PAGES_BOOK_ME_LINK_TO = '/book-me';
const PAGES_CONTACT_AS = 'a';
const PAGES_CONTACT_ID = 'contact';
const PAGES_CONTACT_LINK_TO = '/contact';
const PAGES_HOME_AS = 'a';
const PAGES_HOME_ID = 'home';
const PAGES_HOME_LINK_TO = '/';
const PAGES_PLANS_AND_PRICING_AS = 'a';
const PAGES_PLANS_AND_PRICING_ID = 'plans-and-pricing';
const PAGES_PLANS_AND_PRICING_LINK_TO = '/plans-and-pricing';
const SOCIALS_A_TARGET = '_blank';
const SOCIALS_DATA_FILE_NAME = 'socials.json';
const SOCIALS_GRID_COLUMN_WIDTH = 4;
const SOCIALS_HEADER_AS = 'h4';
const SOCIALS_HEADER_INVERTED = true;
const SOCIALS_INVERTED = true;
const SOCIALS_LINK = true;

function Footing(props) {
  // State
  const [ socials, setSocials ] = useState({});

  // Hooks
  useEffect(() => {
    import('./../../data/' + SOCIALS_DATA_FILE_NAME)
      .then(data => setSocials(data.socials));
  }, []);

  // Navigate
  const navigate = useNavigate();

  // Renderers
  function renderSocial(social) {
    let result;

    if (social.username) {
      result = (
        <List.Item>
          { social.name + ': ' + social.username }
        </List.Item>
      );
    }
    else if (social.url) {
      result = (
        <List.Item>
          <a href={social.url} target={SOCIALS_A_TARGET}>
            { social.name }
          </a>
        </List.Item>
      );
    }
    else {
      result = (
        <List.Item>
          { social.name + ': ' + social.username }
        </List.Item>
      );
    }

    return result;
  }

  function renderSocials(socials) {
    return (
      <List
        inverted={SOCIALS_INVERTED}
        link={SOCIALS_LINK}
      >
        {
          Object.keys(socials).map(socialId => renderSocial(socials[socialId]))
        }
      </List>
    );
  }

  return (
    <Container className="Footing">
      <Grid
        divided={CONTAINER_GRID_DIVIDED}
        stackable={CONTAINER_GRID_STACKABLE}
      >
        <Grid.Row>
          <Grid.Column width={PAGES_GRID_COLUMN_WIDTH}>
            <Header
              as={PAGES_HEADER_AS}
              content={props.texts['FOOTER_PAGES_HEADER_LABEL']}
              inverted={PAGES_HEADER_INVERTED}
            />
            <List
              inverted={PAGES_INVERTED}
              link={PAGES_LINK}
            >
              <List.Item
                active={props.activePage === PAGES_HOME_ID}
                as={PAGES_HOME_AS}
                onClick={() => navigate(PAGES_HOME_LINK_TO)}
              >
                { props.texts['FOOTER_PAGES_HOME_LABEL'] }
              </List.Item>
              <List.Item
                active={props.activePage === PAGES_PLANS_AND_PRICING_ID}
                as={PAGES_PLANS_AND_PRICING_AS}
                onClick={() => navigate(PAGES_PLANS_AND_PRICING_LINK_TO)}
              >
                { props.texts['FOOTER_PAGES_PLANS_AND_PRICING_LABEL'] }
              </List.Item>
              <List.Item
                active={props.activePage === PAGES_CONTACT_ID}
                as={PAGES_CONTACT_AS}
                onClick={() => navigate(PAGES_CONTACT_LINK_TO)}
              >
                { props.texts['FOOTER_PAGES_CONTACT_LABEL'] }
              </List.Item>
              <List.Item
                active={props.activePage === PAGES_BOOK_ME_ID}
                as={PAGES_BOOK_ME_AS}
                onClick={() => navigate(PAGES_BOOK_ME_LINK_TO)}
              >
                { props.texts['FOOTER_PAGES_BOOK_ME_LABEL'] }
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={SOCIALS_GRID_COLUMN_WIDTH}>
            <Header
              as={SOCIALS_HEADER_AS}
              content={props.texts['FOOTER_SOCIALS_HEADER_LABEL']}
              inverted={SOCIALS_HEADER_INVERTED}
            />
              {
                socials
                && renderSocials(socials)
              }
          </Grid.Column>
          <Grid.Column width={MESSAGE_GRID_COLUMN_WIDTH}>
            <Header as={MESSAGE_HEADER_AS} inverted={MESSAGE_HEADER_INVERTED}>
              { props.texts['FOOTER_MESSAGE_TITLE'] }
            </Header>
            <p>
              { props.texts['FOOTER_MESSAGE_DESCRIPTION'] }
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default Footing;
