// Custom Modules
import {
  /*
   * Auth
   */
  // Auth Operations
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,

  /*
   * Payments
   */
  UPDATE_PAYMENT_SUCCESS,

  /*
   * User
   */
  READ_USER_SESSION_MESSENGER_ID_REQUEST,
  READ_USER_SESSION_MESSENGER_ID_SUCCESS,
  READ_USER_SESSION_MESSENGER_ID_FAILURE,
  UPDATE_USER_SESSION_MESSENGER_ID_REQUEST,
  UPDATE_USER_SESSION_MESSENGER_ID_SUCCESS,
  UPDATE_USER_SESSION_MESSENGER_ID_FAILURE,

  /*
   * UI
   */
  OPEN_POPUP,
  CLOSE_POPUP,
  RESET_PAGE,
} from './../actionTypes';

// Data
function getInitialUser() {
  let result;

  const localStorageUser = JSON.parse(localStorage.getItem('user'));

  if (
    localStorageUser
    && localStorageUser.id
    && localStorageUser.email
    && localStorageUser.type
  ) {
    result = {
      id: localStorageUser.id,
      email: localStorageUser.email,
      type: localStorageUser.type,
    };
  }
  else {
    result = {};
  }

  return result;
}

const initialUser = getInitialUser();
const initialState = {
  current: initialUser || {},
  errors: [],
  fetching: false,
  fetched: false,
  notice: null,
};

const reducer = (state = initialState, action) => {
  let result;

  switch (action.type) {
    /*
     * Auth
     */
    // Auth Operations
    case SIGN_IN_REQUEST:
      localStorage.clear();

      result = {
        ...state,
        current: {},
        fetching: true,
        fetched: false,
      };

      break;
    case SIGN_IN_SUCCESS:
      result = {
        ...state,
        current: action.payload.user,
        fetching: false,
        fetched: true,
      };

      localStorage.setItem('user', JSON.stringify({
        id: action.payload.user.id,
        email: action.payload.user.email,
        type: action.payload.user.type,
      }));

      break;
    case SIGN_IN_FAILURE:
      localStorage.clear();

      result = {
        ...state,
        current: {},
        fetching: false,
        fetched: false,
      };

      break;
    case SIGN_OUT_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case SIGN_OUT_SUCCESS:
      localStorage.clear();

      result = {
        ...state,
        current: {},
        fetching: false,
        fetched: true,
      };

      break;
    case SIGN_OUT_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;

    /*
     * Payments
     */
    case UPDATE_PAYMENT_SUCCESS:
      result = {
        ...state,
        current: {
          ...state.current,
          ...action.payload.user,
        },
      };

      localStorage.setItem('user', JSON.stringify({
        id: action.payload.user.id,
        email: action.payload.user.email,
        type: action.payload.user.type,
      }));

      break;

    /*
     * User
     */
    case READ_USER_SESSION_MESSENGER_ID_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case READ_USER_SESSION_MESSENGER_ID_SUCCESS:
      result = {
        ...state,
        current: {
          ...state.current,
          sessionMessengerId: action.payload.sessionMessengerId,
        },
        fetching: false,
        fetched: true,
      };

      break;
    case READ_USER_SESSION_MESSENGER_ID_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;
    case UPDATE_USER_SESSION_MESSENGER_ID_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case UPDATE_USER_SESSION_MESSENGER_ID_SUCCESS:
      result = {
        ...state,
        current: {
          ...state.current,
          sessionMessengerId: action.payload.sessionMessengerId,
        },
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case UPDATE_USER_SESSION_MESSENGER_ID_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;

    /*
     * UI
     */
    case OPEN_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: null,
      };

      break;
    case CLOSE_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: null,
      };

      break;
    case RESET_PAGE:
      result = {
        ...initialState,
      };

      break;
    default:
      result = state;
  }

  return result;
};

export default reducer;
