// Custom Modules
import Layout from './../components/Layout';
import ListPayments from './../components/payments/ListPayments';

function PaymentsPage(props) {

  // Guard (Current User)
  if (props.data && props.data.currentUser && !props.data.currentUser.id) {
    window.location.replace('/');
    return;
  }

  return (
    <div className="PaymentsPage">
      <Layout>
        <ListPayments
          data={{
            currentUser: props.data.currentUser,
            token: props.data.token,
            activePayment: props.data.activePayment,
            payments: props.data.payments,
            pagination: props.data.pagination,
            fetching: props.data.fetching,
            fetched: props.data.fetched,
          }}
          handlers={{
            listPayments: props.handlers.listPayments,
            selectPayment: props.handlers.selectPayment,
            openPopupCreatePayment: props.handlers.openPopupCreatePayment,
            openPopupUpdatePayment: props.handlers.openPopupUpdatePayment,
          }}
          texts={props.texts}
        />
      </Layout>
    </div>
  );
}

export default PaymentsPage;
