// NPM Packages
import axios from 'axios';

// Custom Modules
import {
  // CRUD Operations
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAILURE,
  LIST_VIDEOS_REQUEST,
  LIST_VIDEOS_SUCCESS,
  LIST_VIDEOS_FAILURE,
  READ_VIDEO_REQUEST,
  READ_VIDEO_SUCCESS,
  READ_VIDEO_FAILURE,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE,
  SELECT_VIDEO,
} from './../actionTypes';

// CRUD Operations
function createVideoRequest(token, video, dispatch) {
  dispatch({
    type: CREATE_VIDEO_REQUEST,
  });

  const formData = new FormData();
  formData.append('index', video.index);
  formData.append('name', video.name);
  formData.append('description', video.description);
  formData.append('duration', video.duration);
  formData.append(
    'file',
    video.file,
    video.file.name,
  );

  const url = process.env.REACT_APP_API_GTWY_URL + '/videos';
  axios.post(url, formData, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        createVideoSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        createVideoFailure(error.response.data)
      );
    });
}

function createVideoSuccess(data) {
  return {
    payload: data,
    type: CREATE_VIDEO_SUCCESS,
  };
}

function createVideoFailure(data) {
  return {
    payload: data,
    type: CREATE_VIDEO_FAILURE,
  };
}

function listVideosRequest(token, pagination, dispatch) {
  dispatch({
    type: LIST_VIDEOS_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/videos';
  axios.get(url, {
    headers: {
      'x-auth': token,
    },
    params: {
      ...pagination,
    }
  })
    .then(response => {
      dispatch(
        listVideosSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        listVideosFailure(error)
      );
    });
}

function listVideosSuccess(data) {
  return {
    payload: data,
    type: LIST_VIDEOS_SUCCESS,
  };
}

function listVideosFailure(data) {
  return {
    payload: data,
    type: LIST_VIDEOS_FAILURE,
  };
}

function readVideoRequest(video, dispatch) {
  dispatch({
    type: READ_VIDEO_REQUEST,
  });
}

function readVideoSuccess(data) {
  return {
    payload: data,
    type: READ_VIDEO_SUCCESS,
  };
}

function readVideoFailure(data) {
  return {
    payload: data,
    type: READ_VIDEO_FAILURE,
  };
}

function updateVideoRequest(token, video, dispatch) {
  dispatch({
    type: UPDATE_VIDEO_REQUEST,
  });

  const formData = new FormData();
  formData.append('index', video.index);
  formData.append('name', video.name);
  formData.append('description', video.description);
  formData.append('duration', video.duration);

  if (video.file) {
    formData.append(
      'file',
      video.file,
      video.file.name,
    );
  }

  const url = process.env.REACT_APP_API_GTWY_URL + '/videos/' + video.id;
  axios.patch(url, formData, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        updateVideoSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        updateVideoFailure(error.response.data)
      );
    });
}

function updateVideoSuccess(data) {
  return {
    payload: data,
    type: UPDATE_VIDEO_SUCCESS,
  };
}

function updateVideoFailure(data) {
  return {
    payload: data,
    type: UPDATE_VIDEO_FAILURE,
  };
}

function deleteVideoRequest(token, videoId, dispatch) {
  dispatch({
    type: DELETE_VIDEO_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/videos/' + videoId;
  axios.delete(url, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        deleteVideoSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        deleteVideoFailure(error)
      );
    });
}

function deleteVideoSuccess(data) {
  return {
    payload: data,
    type: DELETE_VIDEO_SUCCESS,
  };
}

function deleteVideoFailure(data) {
  return {
    payload: data,
    type: DELETE_VIDEO_FAILURE,
  };
}

function selectVideo(videoId, dispatch) {
  dispatch({
    payload: videoId,
    type: SELECT_VIDEO,
  });
}

export {
  // CRUD Operations
  createVideoRequest,
  createVideoSuccess,
  createVideoFailure,
  listVideosRequest,
  listVideosSuccess,
  listVideosFailure,
  readVideoRequest,
  readVideoSuccess,
  readVideoFailure,
  updateVideoRequest,
  updateVideoSuccess,
  updateVideoFailure,
  deleteVideoRequest,
  deleteVideoSuccess,
  deleteVideoFailure,
  selectVideo,
};
