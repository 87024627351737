// NPM Packages
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Button,
  Grid,
  Image,
} from 'semantic-ui-react';

// Styles
import './index.css';

// Constants
const BUTTON_MORE_AS = 'a';
const BUTTON_MORE_COLOR = 'black';
const BUTTON_MORE_SIZE = 'large';
const BUTTON_MORE_TARGET = '_blank';
const IMAGE_ALT = 'Pirate';
const IMAGE_HEIGHT = '480';
const IMAGE_SIZE = 'large';
const IMAGE_SRC = '/images/pirate.jpg';
const IMAGE_WIDTH = '480';
const MARKDOWN_FILE_NAME = 'pirate.md';

function Pirate(props) {
  // State
  const [markdown, setMarkdown ] = useState('');

  // Hooks
  useEffect(() => {
    import('./../../../../markdowns/' + MARKDOWN_FILE_NAME)
      .then(module => {
        fetch(module.default)
          .then(res => res.text())
          .then(text => setMarkdown(text));
      });
  }, []);

  // Handlers
  function handleClickButton() {
    window.open(
      process.env.REACT_APP_OFFICIAL_WEBSITE_URL_PIRATE,
      BUTTON_MORE_TARGET
    );
  }

  return (
    <div className="Pirate">
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={12}>
            <ReactMarkdown children={markdown} />
            <Button
              as={BUTTON_MORE_AS}
              color={BUTTON_MORE_COLOR}
              onClick={handleClickButton}
              size={BUTTON_MORE_SIZE}
            >
              { props.texts['BUTTON_MORE_LABEL'] }
            </Button>
          </Grid.Column>
          <Grid.Column floated="right" width={4}>
            <Image
              alt={IMAGE_ALT}
              height={IMAGE_HEIGHT}
              size={IMAGE_SIZE}
              src={IMAGE_SRC}
              width={IMAGE_WIDTH}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Pirate;
