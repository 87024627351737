// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  /*
   * UI
   */
  closePopup,
} from './../store/actions';
import { HomePage } from './../pages';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * User
       */
      currentUser: state.user.current,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * UI
       */
      closePopup: () => {
        ownProps.handlers.closePopup();

        closePopup(dispatch);
      },
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
