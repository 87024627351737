// NPM packages
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

// Constants
const GRID_COLUMN_TEXT_ALIGN = 'center';
const VIDEO_CONTROLS_LIST = 'nodownload';
const VIDEO_CROSS_ORIGIN = 'use-credentials';
const VIDEO_HEIGHT = '894.3';
const VIDEO_PRELOAD = 'metadata';
const VIDEO_TYPE = 'video/mp4';

function ReadVideo(props) {
  // Params
  const params = useParams();

  // SRCs/URLs
  const videoStreamSrc = process.env.REACT_APP_API_GTWY_URL
    + '/videos/' + params.videoId + '?token=' + props.data.token;
  const videoThumbnailSrc = process.env.REACT_APP_API_GTWY_URL
    + '/videos/' + params.videoId + '/thumbnail?token=' + props.data.token;

  return (
    <div className="ReadVideo">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign={GRID_COLUMN_TEXT_ALIGN}>
            <video
              controls
              controlsList={VIDEO_CONTROLS_LIST}
              crossOrigin={VIDEO_CROSS_ORIGIN}
              height={VIDEO_HEIGHT}
              playsInline
              poster={videoThumbnailSrc}
              preload={VIDEO_PRELOAD}
              src={videoStreamSrc}
              type={VIDEO_TYPE}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ReadVideo;
