// NPM Packages
import { useEffect } from 'react';
import {
  Container,
  Grid,
  Pagination,
  Table,
} from 'semantic-ui-react';

// Custom Modules
import Loader from './../../Loader';
import User from './User';

// Styles
import './index.css';

// Constants
const USERS_PAGINATION_COL_SPAN = 12;
const USERS_PAGINATION_FLOATED = 'right';
const USERS_PAGINATION_ACTIVE_PAGE_DEFAULT = 1;
const USERS_PAGINATION_TOTAL_PAGES_DEFAULT = 1;

function ListUsers(props) {
  // Hooks
  useEffect(() => {
    if (
      props.data.token
      && !props.data.fetching
      && !props.data.fetched
    ) {
      props.handlers.listUsers(props.data.token)
    }
  }, [
    props.token,
    props.data.token,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
    props.handlers.listUsers,
  ]);

  // Handlers
  function handleChangePagination(event, { activePage }) {
    const pagination = { activePage };

    props.handlers.listUsers(props.data.token, pagination)
  }

  // Renderers
  function renderLoader() {
    return (
      <Loader texts={props.texts} />
    );
  }

  function renderLoaderOrUsers() {
    let result;

    if (
      props.data.users
      && Object.keys(props.data.users).length > 0
    ) {
      result = renderUsers(props.data.users);
    }
    else {
      result = renderLoader();
    }

    return result;
  }

  function renderUser(user) {
    return (
      <User
        data={{
          user,
        }}
        handlers={{
        }}
        key={user.id}
        texts={props.texts}
      />
    );
  }

  function renderUsers(users) {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_ID_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_EMAIL_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_SIGN_IN_COUNT_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_EMAIL_CONFIRMED_AT_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_LOCKED_AT_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_IS_ADMIN_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_IS_CONNECTED_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_IS_SUBSCRIBED_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_IS_SUBSCRIPTION_ACTIVE_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_SUBSCRIPTION_EXPIRED_AT_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_CREATED_AT_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['USERS_LIST_USER_UPDATED_AT_LABEL'] }
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          { users && users.map(user => renderUser(user)) }
        </Table.Body>

        <Table.Footer>
          {
            props.data.users
            && Object.keys(props.data.users).length > 0
            && renderUsersPagination(props.data.users)
          }
        </Table.Footer>
      </Table>
    );
  }

  function renderUsersPagination(users) {
    const activePage = (
      props.data.pagination
      && props.data.pagination.activePage
    ) || USERS_PAGINATION_ACTIVE_PAGE_DEFAULT;
    const totalPages = (
      props.data.pagination
      && props.data.pagination.totalPages
    ) || USERS_PAGINATION_TOTAL_PAGES_DEFAULT;

    return (
      <Table.Row>
        <Table.HeaderCell colSpan={USERS_PAGINATION_COL_SPAN}>
          <Pagination
            activePage={activePage}
            floated={USERS_PAGINATION_FLOATED}
            onPageChange={handleChangePagination}
            totalPages={totalPages}
          />
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  return (
    <div className="ListUsers">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Container fluid>
              { renderLoaderOrUsers() }
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ListUsers;
