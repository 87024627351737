// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  /*
   * User
   */
  updateUserSessionMessengerIdRequest,

  /*
   * UI
   */
  resetPage,
} from './../store/actions';
import { UpdateUserSessionMessengerId } from './../components/user';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * User
       */
      currentUser: state.user.current,
      fetching: state.user.fetching,
      fetched: state.user.fetched,
      notice: state.user.notice,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * User
       */
      updateUserSessionMessengerId: (token, sessionMessengerId) => {
        updateUserSessionMessengerIdRequest(token, sessionMessengerId, dispatch);
      },

      /*
       * UI
       */
      resetPage: () => resetPage(dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserSessionMessengerId);
