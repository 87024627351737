// NPM Packages
import { Modal } from 'semantic-ui-react'

// Styles
import './index.css';

// Constants
const POPUP_CLOSE_ICON = true;
const POPUP_CLOSE_ON_DIMMER_CLICK = false;
const POPUP_CLOSE_ON_ESCAPE = false;

function Popup(props) {
  // Media
  const Media = props.Media;

  return (
    <>
      <Media greaterThan="mobile">
        <Modal
          className="PopupDesktop"
          closeIcon={POPUP_CLOSE_ICON}
          closeOnDimmerClick={POPUP_CLOSE_ON_DIMMER_CLICK}
          closeOnEscape={POPUP_CLOSE_ON_ESCAPE}
          onClose={props.handlers.closePopup}
          open={props.data.opened}
        >
          { props.data.body }
        </Modal>
      </Media>
      <Media at="mobile">
        <Modal
          className="PopupMobile"
          closeIcon={POPUP_CLOSE_ICON}
          closeOnDimmerClick={POPUP_CLOSE_ON_DIMMER_CLICK}
          closeOnEscape={POPUP_CLOSE_ON_ESCAPE}
          onClose={props.handlers.closePopup}
          open={props.data.opened}
        >
          { props.data.body }
        </Modal>
      </Media>
    </>
  );
}

export default Popup;
