// NPM Packages
import { Button, Card } from 'semantic-ui-react';

// Styles
import './index.css';

function Plan(props) {
  // Renderers
  function renderFeature(feature) {
    return (
      <li key={feature.id}>
        <b>{ feature.name + ': ' }</b>{ feature.description }
      </li>
    );
  }

  function renderFeatures(features) {
    return (
      <ul>
        {
          Object.keys(features)
            .map(featureId => renderFeature(features[featureId]))
        }
      </ul>
    );
  }

  function renderPrice(plan) {
    let result;

    if (plan.oldPrice && plan.newPrice) {
      result = (
        <>
          <strike>{ plan.oldPrice }</strike> { plan.newPrice }
        </>
      );
    }
    else {
      result = (
        <>
          { plan.price }
        </>
      );
    }

    return result;
  }

  return (
    <Card className="Plan">
      <Card.Content className="PlanHeader">
        <Card.Header>
          { props.data.plan.name }
        </Card.Header>
      </Card.Content>
      <Card.Content className="PlanDescription">
        <Card.Description>
          <div className="Pricing">
            <div className="Price">{ renderPrice(props.data.plan) }</div>
            <div className="Time">{ ' / ' + props.data.plan.time }</div>
          </div>
          <div className="Features">
            {
              props.data.plan
              && props.data.plan.features
              && renderFeatures(props.data.plan.features)
            }
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content className="PlanFooter">
        <Button
          color={props.data.plan.button.color}
          onClick={props.handlers.openPopupSignIn}
          size={props.data.plan.button.size}
        >
          { props.data.plan.button.label }
        </Button>
      </Card.Content>
    </Card>
  );
}

export default Plan;
