// NPM Packages
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Modal } from 'semantic-ui-react'

// Styles
import './index.css';

// Constants
const MARKDOWN_FILE_NAME = 'getStarted.md';

function GetStarted(props) {
  // State
  const [ markdown, setMarkdown ] = useState('');

  // Hooks
  useEffect(() => {
    import('./../../markdowns/' + MARKDOWN_FILE_NAME)
      .then(module => {
        fetch(module.default)
          .then(res => res.text())
          .then(text => setMarkdown(text));
      });
  });

  return (
    <>
      <Modal.Header>
        { props.texts['GET_STARTED_MODAL_HEADER_GET_STARTED_LABEL'] }
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ReactMarkdown children={markdown} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={props.handlers.openPopupSignIn}
          secondary
        >
          { props.texts['GET_STARTED_BUTTON_SIGN_IN_LABEL'] }
        </Button>
        <Button
          onClick={props.handlers.openPopupSignUp}
          primary
        >
          { props.texts['GET_STARTED_BUTTON_SIGN_UP_LABEL'] }
        </Button>
      </Modal.Actions>
    </>
  );
}

export default GetStarted;
