// NPM Packages
import { useEffect, useRef } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// Styles
import './index.css';

// Constants
const HCAPTCHA_THEME = 'light';
const HCAPTCHA_SENTRY = false;

function Captcha(props) {
  // hCaptcha
  const captchaRef = useRef();

  // Hooks
  useEffect(() => {
    if (!props.data.fetching && props.data.errored) {
      captchaRef.current.resetCaptcha();

      props.handlers.resetCaptcha();
    }
  }, [
    props.data,
    props.data.fetching,
    props.data.errored,
    props.handlers,
  ]);

  // Handlers
  function handleOpen() {
    console.log("HCaptcha [onOpen]: The user display of a challenge starts.");
  };

  function handleClose() {
    console.log("HCaptcha [onClose]: The user dismisses a challenge.");
  };

  function handleError(error) {
    console.log("HCaptcha [onError]:", error);
  };

  function handleChallengeExpired() {
    console.log("HCaptcha [onChalExpired]: The user display of a challenge times out with no answer.");
  };

  return (
    <div className="Captcha">
      <HCaptcha
        onChalExpired={handleChallengeExpired}
        onClose={handleClose}
        onError={handleError}
        onOpen={handleOpen}
        onVerify={props.handlers.handleUpdateCaptchaToken}
        ref={captchaRef}
        sentry={HCAPTCHA_SENTRY}
        sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
        theme={HCAPTCHA_THEME}
      />
    </div>
  );
}

export default Captcha;
