// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  /*
   * Auth
   */
  signOutRequest,

  /*
   * UI
   */
  openPopup,
} from './../store/actions';
import App from './../components/App';

function mapStateToProps(state) {
  return {
    data: {

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * User
       */
      currentUser: state.user.current,
    },
  };
}


function mapDispatchToProps(dispatch) {
  return {
    handlers: {

      /*
       * Auth
       */
      signOut: user => signOutRequest(user, dispatch),

      /*
       * UI
       */
      openPopup: () => openPopup(dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
