// Custom Modules
import {
  RESET_CAPTCHA,
  RESET_PAGE,
  OPEN_POPUP,
  CLOSE_POPUP,
} from './../actionTypes';

function openPopup(dispatch) {
  dispatch({
    type: OPEN_POPUP,
  });
}

function closePopup(dispatch) {
  dispatch({
    type: CLOSE_POPUP,
  });
}

function resetCaptcha(dispatch) {
  dispatch({
    type: RESET_CAPTCHA,
  });
}

function resetPage(dispatch) {
  dispatch({
    type: RESET_PAGE,
  });
}

export {
  openPopup,
  closePopup,
  resetCaptcha,
  resetPage,
};
