// Custom Modules
import {
  /*
   * Auth
   */
  // Auth Operations
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,

  /*
   * UI
   */
  OPEN_POPUP,
  CLOSE_POPUP,
  RESET_CAPTCHA,
} from './../actionTypes';

// Data
function getInitialAuth() {
  let result;

  const localStorageAuth = JSON.parse(localStorage.getItem('auth'));

  if (
    localStorageAuth
    && localStorageAuth.token
    && localStorageAuth.expiredAt
  ) {
    result = {
      token: localStorageAuth.token,
      expiredAt: localStorageAuth.expiredAt,
    };
  }
  else {
    result = {};
  }

  return result;
}

const initialAuth = getInitialAuth();
const initialState = {
  token: (
    initialAuth
    && initialAuth.token
  ) || null,
  expiredAt: (
    initialAuth
    && initialAuth.expiredAt
  ) || null,
  errored: false,
  fetching: false,
  fetched: false,
  notice: null,
};

const reducer = (state = initialState, action) => {
  let result;

  switch (action.type) {
    /*
     * Auth
     */
    // Auth Operations
    case SIGN_UP_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case SIGN_UP_SUCCESS:
      result = {
        ...state,
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case SIGN_UP_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        errored: true,
        notice: action.payload.notice,
      };

      break;
    case CONFIRM_EMAIL_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case CONFIRM_EMAIL_SUCCESS:
      result = {
        ...state,
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case CONFIRM_EMAIL_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;
    case SIGN_IN_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case SIGN_IN_SUCCESS:
      result = {
        ...state,
        token: action.payload.token.token,
        expiredAt: action.payload.token.expiredAt,
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      localStorage.setItem('auth', JSON.stringify({
        token: action.payload.token.token,
        expiredAt: action.payload.token.expiredAt,
      }));

      break;
    case SIGN_IN_FAILURE:
      localStorage.clear();

      result = {
        ...state,
        fetching: false,
        fetched: false,
        errored: true,
        notice: action.payload.notice,
      };

      break;
    case RESET_PASSWORD_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case RESET_PASSWORD_SUCCESS:
      result = {
        ...state,
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case RESET_PASSWORD_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        errored: true,
        notice: action.payload.notice,
      };

      break;
    case CHANGE_PASSWORD_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case CHANGE_PASSWORD_SUCCESS:
      result = {
        ...state,
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case CHANGE_PASSWORD_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;
    case SIGN_OUT_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case SIGN_OUT_SUCCESS:
      localStorage.clear();

      result = {
        ...state,
        token: null,
        expiredAt: null,
        fetching: false,
        fetched: true,
      };

      break;
    case SIGN_OUT_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;

    /*
     * UI
     */
    case OPEN_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: null,
      };

      break;
    case CLOSE_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: null,
      };

      break;
    case RESET_CAPTCHA:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        errored: false,
      };

      break;
    default:
      result = state;
  }

  return result;
};

export default reducer;
