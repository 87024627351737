// NPM Packages
import { useState } from 'react';
import {
  Segment,
  Sidebar,
} from 'semantic-ui-react'

// Custom Modules
import Footing from './../../Footing';
import Hero from './../../Hero';
import SidebarMenu from './SidebarMenu';
import TopMenu from './TopMenu';

// Styles
import './index.css';

// Constants
const FOOTER_INVERTED = true;
const FOOTER_VERTICAL = true;
const HEADER_VIDEO_BACKGROUND_HEIGHT = '1080';
const HEADER_VIDEO_BACKGROUND_PRELOAD = 'metadata';
const HEADER_VIDEO_BACKGROUND_SRC_MP4 = 'videos/hero.mp4';
const HEADER_VIDEO_BACKGROUND_SRC_WEBM = 'videos/hero.webm';
const HEADER_VIDEO_BACKGROUND_THUMBNAIL_SRC = 'videos/thumbnails/hero.jpg';
const HEADER_VIDEO_BACKGROUND_TYPE_MP4 = 'video/mp4';
const HEADER_VIDEO_BACKGROUND_TYPE_WEBM = 'video/webm';

function MobileContainer(props) {
  // Media
  const Media = props.Media;

  // State
  const [ sidebarOpened, setSidebarOpened ] = useState(false);

  // Handlers
  function closeSidebar() {
    setSidebarOpened(false);
  }

  function openSidebar() {
    setSidebarOpened(true);
  }

  // Renderers
  function renderFooter() {
    return (
      <Segment
        className="Footer"
        inverted={FOOTER_INVERTED}
        vertical={FOOTER_VERTICAL}
      >
        <Footing
          activePage={props.activePage}
          texts={props.texts}
        />
      </Segment>
    );
  }

  return (
    <Media as={Sidebar.Pushable} at="mobile" className="MobileContainer">
      <div className="MobileContainerParentWrap">
        <Sidebar.Pushable>
          <SidebarMenu
            data={{
              currentUser: props.data.currentUser,
              sidebarOpened,
            }}
            handlers={{
              closeSidebar,
              openPopupSignIn: props.handlers.openPopupSignIn,
              openPopupSignUp: props.handlers.openPopupSignUp,
              openSidebar,
            }}
            activePage={props.activePage}
            sidebarOpened={sidebarOpened}
            texts={props.texts}
          />

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              className="HeaderSegment"
              inverted
              textAlign="center"
              vertical
            >
              <div className="HeaderVideo">
                <video
                  autoPlay
                  height={HEADER_VIDEO_BACKGROUND_HEIGHT}
                  loop
                  muted
                  playsInline
                  poster={HEADER_VIDEO_BACKGROUND_THUMBNAIL_SRC}
                  preload={HEADER_VIDEO_BACKGROUND_PRELOAD}
                >
                  <source
                    src={HEADER_VIDEO_BACKGROUND_SRC_WEBM}
                    type={HEADER_VIDEO_BACKGROUND_TYPE_WEBM}
                  />
                  <source
                    src={HEADER_VIDEO_BACKGROUND_SRC_MP4}
                    type={HEADER_VIDEO_BACKGROUND_TYPE_MP4}
                  />
                </video>
              </div>
              <div className="HeaderHero">
                <Hero
                  data={{
                    currentUser: props.data.currentUser,
                  }}
                  handlers={{
                    openPopupGetStarted: props.handlers.openPopupGetStarted,
                    openPopupSignIn: props.handlers.openPopupSignIn,
                  }}
                  activePage={props.activePage}
                  isMobile={false}
                  texts={props.texts}
                />
              </div>

              <div className="HeaderTopMenu">
                <TopMenu
                  data={{
                    currentUser: props.data.currentUser,
                  }}
                  handlers={{
                    closeSidebar,
                    openPopupSignIn: props.handlers.openPopupSignIn,
                    openPopupSignUp: props.handlers.openPopupSignUp,
                    openSidebar,
                  }}
                  activePage={props.activePage}
                  texts={props.texts}
                />
              </div>
            </Segment>

            { props.children}

            { renderFooter() }
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    </Media>
  )
}

export default MobileContainer;
