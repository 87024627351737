// NPM Packages
import { Link } from 'react-router-dom';
import {
  Icon,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';

// Styles
import './index.css';

// Constants
const TEXT_ALIGN_CENTER = 'center';
const USER_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
const USER_IS_ADMIN_COLOR_FALSE = 'red';
const USER_IS_ADMIN_COLOR_TRUE = 'green';
const USER_IS_ADMIN_NAME_FALSE = 'x';
const USER_IS_ADMIN_NAME_TRUE = 'check';
const USER_IS_ADMIN_SIZE = 'large';
const USER_IS_CONNECTED_COLOR_FALSE = 'red';
const USER_IS_CONNECTED_COLOR_TRUE = 'green';
const USER_IS_CONNECTED_NAME_FALSE = 'x';
const USER_IS_CONNECTED_NAME_TRUE = 'check';
const USER_IS_CONNECTED_SIZE = 'large';
const USER_IS_SUBSCRIBED_COLOR_FALSE = 'red';
const USER_IS_SUBSCRIBED_COLOR_TRUE = 'green';
const USER_IS_SUBSCRIBED_NAME_FALSE = 'x';
const USER_IS_SUBSCRIBED_NAME_TRUE = 'check';
const USER_IS_SUBSCRIBED_SIZE = 'large';
const USER_IS_SUBSCRIPTION_ACTIVE_COLOR_FALSE = 'red';
const USER_IS_SUBSCRIPTION_ACTIVE_COLOR_TRUE = 'green';
const USER_IS_SUBSCRIPTION_ACTIVE_NAME_FALSE = 'x';
const USER_IS_SUBSCRIPTION_ACTIVE_NAME_TRUE = 'check';
const USER_IS_SUBSCRIPTION_ACTIVE_SIZE = 'large';

function User(props) {
  // Renderers
  function renderUserId(user) {
    return (
      <Link to={'/users/' + user.id}>
        { user.id }
      </Link>
    );
  }

  function renderUserEmailConfirmedAt(user) {
    return moment(user.emailConfirmedAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserLockedAt(user) {
    return moment(user.lockedAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserSubscriptionExpiredAt(user) {
    return moment(user.subscriptionExpiredAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserCreatedAt(user) {
    return moment(user.createdAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserUpdatedAt(user) {
    return moment(user.updatedAt)
      .format(USER_DATE_FORMAT);
  }

  function renderUserIsAdmin(user) {
    let result;

    if (user.isAdmin) {
      result = (
        <Icon
          color={USER_IS_ADMIN_COLOR_TRUE}
          name={USER_IS_ADMIN_NAME_TRUE}
          size={USER_IS_ADMIN_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_ADMIN_COLOR_FALSE}
          name={USER_IS_ADMIN_NAME_FALSE}
          size={USER_IS_ADMIN_SIZE}
        />
      );
    }

    return result;
  }

  function renderUserIsConnected(user) {
    let result;

    if (user.isConnected) {
      result = (
        <Icon
          color={USER_IS_CONNECTED_COLOR_TRUE}
          name={USER_IS_CONNECTED_NAME_TRUE}
          size={USER_IS_CONNECTED_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_CONNECTED_COLOR_FALSE}
          name={USER_IS_CONNECTED_NAME_FALSE}
          size={USER_IS_CONNECTED_SIZE}
        />
      );
    }

    return result;
  }

  function renderUserIsSubscribed(user) {
    let result;

    if (user.isSubscribed) {
      result = (
        <Icon
          color={USER_IS_SUBSCRIBED_COLOR_TRUE}
          name={USER_IS_SUBSCRIBED_NAME_TRUE}
          size={USER_IS_SUBSCRIBED_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_SUBSCRIBED_COLOR_FALSE}
          name={USER_IS_SUBSCRIBED_NAME_FALSE}
          size={USER_IS_SUBSCRIBED_SIZE}
        />
      );
    }

    return result;
  }

  function renderUserIsSubscriptionActive(user) {
    let result;

    if (user.isSubscriptionActive) {
      result = (
        <Icon
          color={USER_IS_SUBSCRIPTION_ACTIVE_COLOR_TRUE}
          name={USER_IS_SUBSCRIPTION_ACTIVE_NAME_TRUE}
          size={USER_IS_SUBSCRIPTION_ACTIVE_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={USER_IS_SUBSCRIPTION_ACTIVE_COLOR_FALSE}
          name={USER_IS_SUBSCRIPTION_ACTIVE_NAME_FALSE}
          size={USER_IS_SUBSCRIPTION_ACTIVE_SIZE}
        />
      );
    }

    return result;
  }

  return (
    <Table.Row>
      <Table.Cell>
        {
          props.data.user.id
          && renderUserId(props.data.user)
        }
      </Table.Cell>
      <Table.Cell>
        { props.data.user.email }
      </Table.Cell>
      <Table.Cell>
        { props.data.user.signInCount }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        {
          props.data.user.emailConfirmedAt
          && renderUserEmailConfirmedAt(props.data.user)
        }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        {
          props.data.user.lockedAt
          && renderUserLockedAt(props.data.user)
        }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        { renderUserIsAdmin(props.data.user) }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        { renderUserIsConnected(props.data.user) }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        { renderUserIsSubscribed(props.data.user) }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        { renderUserIsSubscriptionActive(props.data.user) }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        {
          props.data.user.subscriptionExpiredAt
          && renderUserSubscriptionExpiredAt(props.data.user)
        }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        {
          props.data.user.createdAt
          && renderUserCreatedAt(props.data.user)
        }
      </Table.Cell>
      <Table.Cell textAlign={TEXT_ALIGN_CENTER}>
        {
          props.data.user.updatedAt
          && renderUserUpdatedAt(props.data.user)
        }
      </Table.Cell>
    </Table.Row>
  );
}

export default User;
