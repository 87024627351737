// NPM Packages
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

// Custom Modules
import {
  auth,
  payments,
  ui,
  user,
  users,
  videos,
} from './reducers';

const root = combineReducers({
  auth,
  payments,
  ui,
  user,
  users,
  videos,
});

let store;
if (process.env.NODE_ENV === 'production') {
  store = createStore(root, /* preloadedState, */ compose(
    applyMiddleware(thunk)
  ));
}
else {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    || compose;

  store = createStore(root, /* preloadedState, */ composeEnhancers(
    applyMiddleware(logger, thunk)
  ));
}

export default store;
