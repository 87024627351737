// NPM Packages
import { Loader } from 'semantic-ui-react';

// Constants
const LOADER_ACTIVE = true;

function CustomLoader(props) {
  return (
    <Loader active={LOADER_ACTIVE}>
      { props.texts['LOADER_LABEL'] }
    </Loader>
  );
}

export default CustomLoader;
