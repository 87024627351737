// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import { AccountPage } from './../pages';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * User
       */
      currentUser: state.user.current,
      fetching: state.user.fetching,
      fetched: state.user.fetched,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
