// NPM Packages
import {
  Icon,
  Label,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';

// Custom Modules
import { formatAmount } from './../../../../helpers';

// Styles
import './index.css';

// Constants
const PAYMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
const PAYMENT_IS_CONFIRMED_COLOR_FALSE = 'red';
const PAYMENT_IS_CONFIRMED_COLOR_TRUE = 'green';
const PAYMENT_IS_CONFIRMED_NAME_FALSE = 'x';
const PAYMENT_IS_CONFIRMED_NAME_TRUE = 'check';
const PAYMENT_IS_CONFIRMED_SIZE = 'large';
const PAYMENT_IS_RECEIVED_COLOR_FALSE = 'red';
const PAYMENT_IS_RECEIVED_COLOR_TRUE = 'green';
const PAYMENT_IS_RECEIVED_NAME_FALSE = 'x';
const PAYMENT_IS_RECEIVED_NAME_TRUE = 'check';
const PAYMENT_IS_RECEIVED_SIZE = 'large';

function Payment(props) {
  // Renderers
  function renderPaymentDate(payment) {
    return moment(payment.date).format(PAYMENT_DATE_FORMAT);
  }

  function renderPaymentIsConfirmed(payment) {
    let result;

    if (payment.isConfirmed) {
      result = (
        <Icon
          color={PAYMENT_IS_CONFIRMED_COLOR_TRUE}
          name={PAYMENT_IS_CONFIRMED_NAME_TRUE}
          size={PAYMENT_IS_CONFIRMED_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={PAYMENT_IS_CONFIRMED_COLOR_FALSE}
          name={PAYMENT_IS_CONFIRMED_NAME_FALSE}
          size={PAYMENT_IS_CONFIRMED_SIZE}
        />
      );
    }

    return result;
  }

  function renderPaymentIsReceived(payment) {
    let result;

    if (payment.isReceived) {
      result = (
        <Icon
          color={PAYMENT_IS_RECEIVED_COLOR_TRUE}
          name={PAYMENT_IS_RECEIVED_NAME_TRUE}
          size={PAYMENT_IS_RECEIVED_SIZE}
        />
      );
    }
    else {
      result = (
        <Icon
          color={PAYMENT_IS_RECEIVED_COLOR_FALSE}
          name={PAYMENT_IS_RECEIVED_NAME_FALSE}
          size={PAYMENT_IS_RECEIVED_SIZE}
        />
      );
    }

    return result;
  }

  return (
    <Table.Row className="Payment">
      <Table.Cell>
        <Label ribbon>{ props.data.payment.nonce }</Label>
      </Table.Cell>
      <Table.Cell>
        {
          process.env.REACT_APP_US_DOLLAR_SYMBOL
          + ''
          + props.data.payment.price
        }
      </Table.Cell>
      <Table.Cell>
        {
          formatAmount(props.data.payment.amount)
          + ' '
          + process.env.REACT_APP_MONERO_TICKER
        }
      </Table.Cell>
      <Table.Cell>{ props.data.payment.address }</Table.Cell>
      <Table.Cell textAlign="center">
        { renderPaymentIsReceived(props.data.payment) }
      </Table.Cell>
      <Table.Cell textAlign="center">
        { renderPaymentIsConfirmed(props.data.payment) }
      </Table.Cell>
      <Table.Cell textAlign="center">
        { renderPaymentDate(props.data.payment) }
      </Table.Cell>
    </Table.Row>
  );
}

export default Payment;
