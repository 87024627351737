// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  /*
   * Auth
   */
  signOutRequest,
} from './../store/actions';
import SignOut from './../components/auth/SignOut';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * Auth
       */
      token: state.auth.token,
      fetching: state.auth.fetching,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Auth
       */
      signOut: token => signOutRequest(token, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
