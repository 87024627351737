// Custom Modules
import ChangePasswordPageComponent from './../components/auth/ChangePasswordPage';
import Layout from './../components/Layout';

function ChangePasswordPage(props) {
  return (
    <div className="ChangePasswordPage">
      <Layout>
        <ChangePasswordPageComponent
          data={{
            popupOpened: props.data.popupOpened,
          }}
          handlers={{
            openPopupChangePassword: props.handlers.openPopupChangePassword,
          }}
          texts={props.texts}
          textsLoaded={props.textsLoaded}
        />
      </Layout>
    </div>
  );
}

export default ChangePasswordPage;
