// NPM Packages
import { Segment } from 'semantic-ui-react';

// Custom Modules
import Dero from './Dero';
import Mission from './Mission';
import Monero from './Monero';
import Partners from './Partners';
import Pirate from './Pirate';
import Vision from './Vision';

// Styles
import './index.css';

// Constants
const DERO_ID = 'dero';
const MISSION_ID = 'mission';
const MONERO_ID = 'monero';
const PARTNERS_ID = 'partners';
const PIRATE_ID = 'pirate';
const SEGMENT_VERTICAL = true;
const VISION_ID = 'vision';

function Home(props) {
  return (
    <div className="Home">
      <Segment
        className="FirstSegment"
        id={VISION_ID}
        vertical={SEGMENT_VERTICAL}
      >
        <Vision texts={props.texts}/>
      </Segment>
      <Segment
        className="Segment"
        id={MISSION_ID}
        vertical={SEGMENT_VERTICAL}
      >
        <Mission texts={props.texts}/>
      </Segment>
      <Segment
        className="Segment"
        id={MONERO_ID}
        vertical={SEGMENT_VERTICAL}
      >
        <Monero texts={props.texts}/>
      </Segment>
      <Segment
        className="Segment"
        id={DERO_ID}
        vertical={SEGMENT_VERTICAL}
      >
        <Dero texts={props.texts}/>
      </Segment>
      <Segment
        className="Segment"
        id={PIRATE_ID}
        vertical={SEGMENT_VERTICAL}
      >
        <Pirate texts={props.texts}/>
      </Segment>
      <Segment
        className="Segment"
        id={PARTNERS_ID}
        vertical={SEGMENT_VERTICAL}
      >
        <Partners texts={props.texts}/>
      </Segment>
    </div>
  );
}

export default Home;
