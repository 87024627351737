// Custom Modules
import {
  /*
   * Users
   */
  // CRUD Operations
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILURE,
  READ_USER_REQUEST,
  READ_USER_SUCCESS,
  READ_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,

  SELECT_USER,

  /*
   * UI
   */
  OPEN_POPUP,
  CLOSE_POPUP,
  RESET_PAGE,
} from './../actionTypes';

// Helpers

// Data
const initialState = {
  ids: [],
  items: {},
  pagination: {},
  selected: {},
  fetching: false,
  fetched: false,
  notice: null,
};

const reducer = (state = initialState, action) => {
  let result;

  switch (action.type) {
    /*
     * Users
     */
    // CRUD Operations
    case LIST_USERS_REQUEST:
      result = {
        ...state,
        ids: [],
        items: {},
        fetching: true,
        fetched: false,
      };

      break;
    case LIST_USERS_SUCCESS:
      result = {
        ...state,
        ids: action.payload.users.map(user => user.id),
        items: action.payload.users.reduce((items, user) => {
          return {
            ...items,
            [user.id]: user,
          };
        }, {}),
        pagination: action.payload.pagination,
        fetching: false,
        fetched: true,
      };

      break;
    case LIST_USERS_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case READ_USER_REQUEST:
      result = {
        ...state,
        selected: {},
        fetching: true,
        fetched: false,
      };

      break;
    case READ_USER_SUCCESS:
      result = {
        ...state,
        selected: action.payload.user,
        fetching: false,
        fetched: true,
      };

      break;
    case READ_USER_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case UPDATE_USER_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case UPDATE_USER_SUCCESS:
      result = {
        ...state,
        items: {
          ...state.items,
          [action.payload.user.id]: action.payload.user,
        },
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case UPDATE_USER_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;
    case DELETE_USER_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case DELETE_USER_SUCCESS:
      const userId = action.payload.user.id;
      const ids = state.ids.filter(id => id !== userId);
      const items = state.items;
      delete items[userId];

      result = {
        ...state,
        activeId: (state.activeId === userId) ? null : state.activeId,
        ids,
        items,
        fetching: false,
        fetched: true,
      };

      break;
    case DELETE_USER_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case SELECT_USER:
      result = {
        ...state,
        activeId: action.payload,
      };

      break;

    /*
     * UI
     */
    case OPEN_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case CLOSE_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case RESET_PAGE:
      result = {
        ...initialState,
      };

      break;
    default:
      result = state;
  }

  return result;
};

export default reducer;
