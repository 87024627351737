// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  /*
   * Auth
   */
  resetPasswordRequest,

  /*
   * UI
   */
  closePopup,
  resetCaptcha,
} from './../store/actions';
import ResetPassword from './../components/auth/ResetPassword';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * Auth
       */
      fetching: state.auth.fetching,
      fetched: state.auth.fetched,
      errored: state.auth.errored,
      notice: state.auth.notice,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Auth
       */
      resetPassword: user => {
        resetPasswordRequest(user, dispatch);
      },

      /*
       * UI
       */
      closePopup: () => {
        ownProps.handlers.closePopup();

        closePopup(dispatch);
      },
      resetCaptcha: () => resetCaptcha(dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
