// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import { confirmEmailRequest } from './../store/actions';
import ConfirmEmail from './../components/auth/ConfirmEmail';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * Auth
       */
      fetching: state.auth.fetching,
      fetched: state.auth.fetched,
      notice: state.auth.notice,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Auth
       */
      confirmEmail: token => {
        confirmEmailRequest(token, dispatch);
      },
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
