// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import { changePasswordRequest } from './../store/actions';
import ChangePassword from './../components/auth/ChangePassword';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * Auth
       */
      fetching: state.auth.fetching,
      fetched: state.auth.fetched,
      notice: state.auth.notice,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Auth
       */
      changePassword: (user, token) => {
        changePasswordRequest(user, token, dispatch);
      },
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
