// NPM Packages
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Styles
import './index.css';

function ChangePasswordPage(props) {
  // Params
  const params = useParams();

  // Automatically Open Popup
  useEffect(() => {
    if (props.textsLoaded && !props.data.popupOpened) {
      props.handlers.openPopupChangePassword(params.token);
    }
  }, [
    params.token,
    props.data.popupOpened,
    props.handlers,
    props.textsLoaded,
  ]);

  return (
    <div className="ChangePasswordPage">
    </div>
  );
}

export default ChangePasswordPage;
