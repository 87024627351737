// Custom Modules
import {
  /*
   * Payments
   */
  // CRUD Operations
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  LIST_PAYMENTS_REQUEST,
  LIST_PAYMENTS_SUCCESS,
  LIST_PAYMENTS_FAILURE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,

  /*
   * UI
   */
  OPEN_POPUP,
  CLOSE_POPUP,
  RESET_PAGE,
} from './../actionTypes';

// Helpers

// Data
const initialState = {
  activeId: null,
  ids: [],
  items: {},
  pagination: {},
  errors: [],
  fetching: false,
  fetched: false,
  notice: null,
};

const reducer = (state = initialState, action) => {
  let result;

  switch (action.type) {
    /*
     * Payments
     */
    // CRUD Operations
    case CREATE_PAYMENT_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case CREATE_PAYMENT_SUCCESS:
      result = {
        ...state,
        activeId: action.payload.payment.id,
        ids: [
          action.payload.payment.id,
          ...state.ids,
        ],
        items: {
          ...state.items,
          [action.payload.payment.id]: action.payload.payment,
        },
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case CREATE_PAYMENT_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;
    case LIST_PAYMENTS_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case LIST_PAYMENTS_SUCCESS:
      result = {
        ...state,
        ids: action.payload.payments.map(payment => payment.id),
        items: action.payload.payments.reduce((items, payment) => {
          return {
            ...items,
            [payment.id]: payment,
          };
        }, {}),
        pagination: action.payload.pagination,
        fetching: false,
        fetched: true,
      };

      break;
    case LIST_PAYMENTS_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case UPDATE_PAYMENT_REQUEST:
      result = {
        ...state,
        fetching: true,
        fetched: false,
      };

      break;
    case UPDATE_PAYMENT_SUCCESS:
      result = {
        ...state,
        items: {
          ...state.items,
          [action.payload.payment.id]: action.payload.payment,
        },
        fetching: false,
        fetched: true,
        notice: action.payload.notice,
      };

      break;
    case UPDATE_PAYMENT_FAILURE:
      result = {
        ...state,
        fetching: false,
        fetched: false,
        notice: action.payload.notice,
      };

      break;

    /*
     * UI
     */
    case OPEN_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case CLOSE_POPUP:
      result = {
        ...state,
        fetching: false,
        fetched: false,
      };

      break;
    case RESET_PAGE:
      result = {
        ...initialState,
      };

      break;
    default:
      result = state;
  }

  return result;
};

export default reducer;
