// Styles
import './index.css';

function NotFound(props) {
  return (
    <h1>{ props.texts['NOT_FOUND_LABEL'] }</h1>
  )
}

export default NotFound;
