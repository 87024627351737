// Custom Modules
import Contact from './../../components/website/Contact';
import WebsiteLayout from './../../components/WebsiteLayout';

function ContactPage(props) {
  return (
    <div className="ContactPage">
      <WebsiteLayout>
        <Contact
          activePage={props.activePage}
          texts={props.texts}
        />
      </WebsiteLayout>
    </div>
  );
}

export default ContactPage;
