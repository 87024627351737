// Styles
import './index.css';

function WebsiteLayout(props) {
  return (
    <div className="WebsiteLayout">
      { props.children }
    </div>
  );
}

export default WebsiteLayout;
