// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  listPaymentsRequest,
  //selectPayment,
} from './../store/actions';
import { PaymentsPage } from './../pages';

function mapStateToProps(state, ownProps) {
  return {
    //...ownProps,

    data: {
      //...ownProps.data,

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * Payments
       */
      activePayment: state.payments.items[state.payments.activeId],
      payments: state.payments.ids.map(id => state.payments.items[id]),
      pagination: state.payments.pagination,
      fetching: state.payments.fetching,
      fetched: state.payments.fetched,

      /*
       * User
       */
      currentUser: state.user.current,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) { return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Payments
       */
      listPayments: (token, pagination = {}) => listPaymentsRequest(token, pagination, dispatch),
      //selectPayment: (paymentId) => selectPayment(paymentId, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);
