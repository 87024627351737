// NPM Packages
import {
  Button,
  Container,
  Header,
  Image,
} from 'semantic-ui-react'

// Styles
import './index.css';

// Constants
const ACTION_BUTTON_SIZE = 'huge';
const LOGO_IMAGE_HEIGHT = '1002';
const LOGO_IMAGE_SIZE = 'huge';
const LOGO_IMAGE_SRC = '/images/logo/home-page-header-logo.jpg';
const LOGO_IMAGE_WIDTH = '1002';
const HEADER_DESCRIPTION_AS = 'h2';
const USER_TYPE_FREEMIUM = 'FREEMIUM';

function Hero(props) {
  // Handlers
  function handleGetStarted() {
    let result;

    if (
      props.data
      && props.data.currentUser
      && props.data.currentUser.id
      && props.data.currentUser.type === USER_TYPE_FREEMIUM
    ) {
      result = props.handlers.openPopupGetStarted;
    }
    else {
      result = props.handlers.openPopupGetStarted;
    }

    return result;
  }

  // Renderers
  function renderActionButton() {
    return (
      <Button
        className="ActionButton"
        onClick={handleGetStarted()}
        primary
        size={ACTION_BUTTON_SIZE}
      >
        { props.texts['HERO_ACTION_BUTTON_LABEL'] }
      </Button>
    );
  }

  function renderLogo() {
    return (
      <Image
        alt={props.texts['HOME_HEADING_HEADER_LOGO_ALT']}
        bordered
        centered
        circular
        className="Logo"
        height={LOGO_IMAGE_HEIGHT}
        size={LOGO_IMAGE_SIZE}
        src={LOGO_IMAGE_SRC}
        width={LOGO_IMAGE_WIDTH}
      />
    );
  }

  return (
    <Container className="Hero">
      <Header>
        <Header.Content>
          { renderLogo() }
        </Header.Content>
      </Header>
      <Header
        as={HEADER_DESCRIPTION_AS}
        inverted
        style={{
          fontSize: props.mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: props.mobile ? '0.5em' : '1.5em',
        }}
      >
        { props.texts['HOME_HEADING_HEADER_DESCRIPTION_TEXT'] }
      </Header>

      { renderActionButton() }
    </Container>
  );
}

export default Hero;
