// NPM Packages
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Grid,
  Image,
} from 'semantic-ui-react';

// Styles
import './index.css';

// Constants
const MARKDOWN_FILE_NAME = 'partners.md';
const PARTNERS_DERO_WORLD_IMAGE_ALT = 'Dero.World';
const PARTNERS_DERO_WORLD_IMAGE_HEIGHT = '312';
const PARTNERS_DERO_WORLD_IMAGE_SIZE = 'large';
const PARTNERS_DERO_WORLD_IMAGE_SRC = '/images/dero-world.jpg';
const PARTNERS_DERO_WORLD_IMAGE_TARGET = '_blank';
const PARTNERS_DERO_WORLD_IMAGE_WIDTH = '1000';

function Partners(props) {
  // State
  const [markdown, setMarkdown ] = useState('');

  // Hooks
  useEffect(() => {
    import('./../../../../markdowns/' + MARKDOWN_FILE_NAME)
      .then(module => {
        fetch(module.default)
          .then(res => res.text())
          .then(text => setMarkdown(text));
      });
  }, []);

  return (
    <div className="Partners">
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={16}>
            <ReactMarkdown children={markdown} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Image
              alt={PARTNERS_DERO_WORLD_IMAGE_ALT}
              fluid
              //height={PARTNERS_DERO_WORLD_IMAGE_HEIGHT}
              href={process.env.REACT_APP_OFFICIAL_WEBSITE_URL_PARTNERS_DERO_WORLD}
              //size={PARTNERS_DERO_WORLD_IMAGE_SIZE}
              src={PARTNERS_DERO_WORLD_IMAGE_SRC}
              target={PARTNERS_DERO_WORLD_IMAGE_TARGET}
              //width={PARTNERS_DERO_WORLD_IMAGE_WIDTH}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Partners;
