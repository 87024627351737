// Custom Modules
import Layout from './../components/Layout';
import ReadUser from './../components/users/ReadUser';

function UserPage(props) {

  // Guard (Admin)
  if (props.data && props.data.currentUser && !props.data.currentUser.isAdmin) {
    window.location.replace('/');
    return;
  }

  return (
    <div className="UserPage">
      <Layout>
        <ReadUser
          data={{
            token: props.data.token,
            user: props.data.user,
            fetching: props.data.fetching,
            fetched: props.data.fetched,
          }}
          handlers={{
            readUser: props.handlers.readUser,
            openPopupUpdateUser: props.handlers.openPopupUpdateUser,
          }}
          texts={props.texts}
        />
      </Layout>
    </div>
  );
}

export default UserPage;
