function formatAmount(amountInAtomicUnits) {
  const amountInXMR = amountInAtomicUnits
    / process.env.REACT_APP_MONERO_ATOMIC_UNITS;

  return amountInXMR;
}

function formatDuration(duration) {
  // Constants
  const HOURS = 'hours';
  const MINUTES = 'minutes';
  const SECONDS = 'seconds';
  const PAD = '0';
  const LENGTH = 2;
  const NUMBER_OF_MINUTES_IN_ONE_HOUR = 60;
  const NUMBER_OF_SECONDS_IN_ONE_MINUTE = 60;
  const NUMBER_OF_SECONDS_IN_ONE_HOUR = (
    NUMBER_OF_MINUTES_IN_ONE_HOUR * NUMBER_OF_SECONDS_IN_ONE_MINUTE
  );

  // Hours
  const quotientHours = duration / NUMBER_OF_SECONDS_IN_ONE_HOUR;
  const integerPartHours = Math.trunc(quotientHours);
  const decimalPartHours = Math.abs(
    integerPartHours - Math.floor(integerPartHours)
  );
  const hours = integerPartHours;

  // Minutes
  const restMinutes = duration - (hours * NUMBER_OF_SECONDS_IN_ONE_HOUR);
  const quotientMinutes = restMinutes / NUMBER_OF_SECONDS_IN_ONE_MINUTE;
  const integerPartMinutes = Math.trunc(quotientMinutes);
  const decimalPartMinutes = Math.abs(
    integerPartMinutes - Math.floor(integerPartMinutes)
  );
  const minutes = integerPartMinutes;

  // Seconds
  const restSeconds = duration
    - (hours * NUMBER_OF_SECONDS_IN_ONE_HOUR)
    - (minutes * NUMBER_OF_SECONDS_IN_ONE_MINUTE);
  const seconds = Math.ceil(restSeconds);

  // Helpers
  function padStringLeft(string) {
    return (new Array(LENGTH + 1).join(PAD) + string).slice(-LENGTH);
  }

  function format(string, append) {
    return padStringLeft(string) + ' ' + append;
  }

  return format(hours, HOURS)
    + ' '
    + format(minutes, MINUTES)
    + ' '
    + format(seconds, SECONDS);
}

function formatNumber(number) {
  return new Intl.NumberFormat()
    .format(number);
}

module.exports = {
  formatAmount,
  formatDuration,
  formatNumber,
};
