// NPM Packages
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
} from 'semantic-ui-react'

// Custom Modules
import Captcha from './../../Captcha';
import Notice from './../../Notice';

// Styles
import './index.css';

// Constants
const FORM_FIELD_EMAIL_AUTO_COMPLETE = 'email';
const FORM_FIELD_EMAIL_TYPE = 'email';
const FORM_FIELD_PASSWORD_AUTO_COMPLETE = 'current-password';
const FORM_FIELD_PASSWORD_TYPE = 'password';
const FORM_BUTTON_CANCEL_ICON_NAME = 'cancel';
const FORM_BUTTON_SIGN_IN_ICON_NAME = 'sign-in';
const POPUP_CLOSE_TIMEOUT = 500; // 0.5 s (Exception)

function SignIn(props) {
  // State
  const [ user, setUser ] = useState({
    email: '',
    password: '',
    captchaToken: '',
  });

  // Automatically Close Popup
  useEffect(() => {
    if (!props.data.fetching && props.data.fetched) {
      setTimeout(() => {
        props.handlers.closePopup();
      }, POPUP_CLOSE_TIMEOUT);
    }
  }, [
    props.data,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
  ]);

  // Reset Captcha Token
  useEffect(() => {
    if (!props.data.fetching && props.data.errored) {
      handleUpdateUser('captchaToken', '');
    }
  }, [
    props.data,
    props.data.fetching,
    props.data.errored,
  ]);

  // Handlers
  function handlePressEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (isFormComplete() && !props.data.fetching && !props.data.fetched) {
        props.handlers.signIn(user);
      }
    }
  }

  function handleUpdateUser(key, value) {
    setUser({
      ...user,
      [key]: value,
    });
  }

  // Helpers
  function isFormComplete() {
    let result = false;

    if (
      user.email
      && user.password
      && user.captchaToken
    ) {
      result = true;
    }

    return result;
  }

  // Renderers
  function renderNotice(notice) {
    return (
      <div className="Notice">
        <Notice
          data={{
            notice,
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Modal.Header>
        { props.texts['AUTH_MODAL_HEADER_SIGN_IN_LABEL'] }
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>{ props.texts['AUTH_FIELD_EMAIL_LABEL'] }</label>
              <Input
                autoComplete={FORM_FIELD_EMAIL_AUTO_COMPLETE}
                onChange={event => handleUpdateUser('email', event.target.value)}
                onKeyPress={handlePressEnterKey}
                placeholder={ props.texts['AUTH_FIELD_EMAIL_PLACEHOLDER'] }
                type={FORM_FIELD_EMAIL_TYPE}
                value={user.email}
              />
            </Form.Field>
            <Form.Field>
              <label>{ props.texts['AUTH_FIELD_PASSWORD_LABEL'] }</label>
              <Input
                autoComplete={FORM_FIELD_PASSWORD_AUTO_COMPLETE}
                onChange={event => handleUpdateUser('password', event.target.value)}
                onKeyPress={handlePressEnterKey}
                placeholder={ props.texts['AUTH_FIELD_PASSWORD_PLACEHOLDER'] }
                type={FORM_FIELD_PASSWORD_TYPE}
                value={user.password}
              />
            </Form.Field>
            <Captcha
              data={{
                fetching: props.data.fetching,
                errored: props.data.errored,
              }}
              handlers={{
                handleUpdateCaptchaToken: captchaToken => {
                  return handleUpdateUser('captchaToken', captchaToken);
                },
                resetCaptcha: props.handlers.resetCaptcha,
              }}
            />
            <Button
              onClick={props.handlers.openPopupResetPassword}
            >
              { props.texts['AUTH_LINK_FORGOT_PASSWORD_LABEL'] }
            </Button>
            <Button
              onClick={props.handlers.openPopupSignUp}
            >
              { props.texts['AUTH_LINK_DONT_HAVE_ACCOUNT_LABEL'] }
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={props.data.fetching}
          onClick={props.handlers.closePopup}
          secondary
        >
          <Icon name={FORM_BUTTON_CANCEL_ICON_NAME} />
          { props.texts['AUTH_BUTTON_CANCEL_LABEL'] }
        </Button>
        <Button
          disabled={
            !isFormComplete()
            || props.data.fetching
            || props.data.fetched
          }
          loading={props.data.fetching}
          onClick={() => props.handlers.signIn(user)}
          primary
        >
          <Icon name={FORM_BUTTON_SIGN_IN_ICON_NAME} />
          { props.texts['AUTH_BUTTON_SIGN_IN_LABEL'] }
        </Button>
      </Modal.Actions>
      {
        props.data.notice
        && renderNotice(props.data.notice)
      }
    </>
  );
}

export default SignIn;
