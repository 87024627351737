// Custom Modules
import PlansAndPricing from './../../components/website/PlansAndPricing';
import WebsiteLayout from './../../components/WebsiteLayout';

function PlansAndPricingPage(props) {
  return (
    <div className="PlansAndPricingPage">
      <WebsiteLayout>
        <PlansAndPricing
          handlers={{
            closePopup: props.handlers.closePopup,
            openPopupSignIn: props.handlers.openPopupSignIn,
          }}
          activePage={props.activePage}
          texts={props.texts}
        />
      </WebsiteLayout>
    </div>
  );
}

export default PlansAndPricingPage;
