// NPM Packages
import {
  Button,
  Icon,
  Popup,
} from 'semantic-ui-react'

// Styles
import './index.css';

// Constants
const BUTTON_DELETE_VIDEO_COLOR = 'red';
const BUTTON_DELETE_VIDEO_ICON_NAME = 'trash';
const BUTTON_DELETE_VIDEO_ICON = true;
const BUTTON_DELETE_VIDEO_SIZE = 'mini';
const CONFIRMATION_POPUP_ON = 'click';
const CONFIRMATION_POPUP_POSITION = 'right center';
const BUTTON_CONFIRMATION_COLOR = 'red';
const BUTTON_CONFIRMATION_ICON_NAME = 'trash'

function DeleteVideo(props) {
  // Renderers
  function renderConfirmationButton() {
    return (
      <Button
        color={BUTTON_CONFIRMATION_COLOR}
        disabled={props.data.fetching}
        loading={props.data.fetching}
        onClick={() => {
          return props.handlers.deleteVideo(
            props.data.token,
            props.data.videoId
          )
        }}
      >
        <Icon name={BUTTON_CONFIRMATION_ICON_NAME} />
        { props.texts['VIDEOS_DELETE_BUTTON_CONFIRMATION_LABEL'] }
      </Button>
    );
  }

  function renderDeleteButton() {
    return (
      <Button
        className="DeleteVideo"
        color={BUTTON_DELETE_VIDEO_COLOR}
        disabled={props.data.fetching}
        icon={BUTTON_DELETE_VIDEO_ICON}
        loading={props.data.fetching}
        size={BUTTON_DELETE_VIDEO_SIZE}
      >
        <Icon name={BUTTON_DELETE_VIDEO_ICON_NAME} />
      </Button>
    );
  }

  return (
    <Popup
      content={renderConfirmationButton()}
      on={CONFIRMATION_POPUP_ON}
      onClose={props.handlers.closePopup}
      onOpen={props.handlers.openPopup}
      position={CONFIRMATION_POPUP_POSITION}
      trigger={renderDeleteButton()}
    />
  );
}

export default DeleteVideo;
