// NPM Packages
import {
  Container,
  Grid,
  Header,
} from 'semantic-ui-react';

// Custom Modules
import { ReadUserSessionMessengerId } from './../../containers';

// Styles
import './index.css';

// Constants
const HEADER_ACCOUNT_SIZE = 'large';

function Account(props) {
  return (
    <div className="Account">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Container>
              <Grid.Row>
                <Grid.Column>
                  <Header className="AccountHeader" size={HEADER_ACCOUNT_SIZE}>
                    { props.texts['ACCOUNT_HEADER_ACCOUNT_LABEL'] }
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <ReadUserSessionMessengerId
                    handlers={{
                      openPopupUpdateSessionMessengerId: props.handlers
                        .openPopupUpdateSessionMessengerId,
                    }}
                    texts={props.texts}
                  />
                </Grid.Column>
              </Grid.Row>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Account;
