// NPM Packages
import { useEffect, useState } from 'react';
import {
  Grid,
} from 'semantic-ui-react';

// Custom Modules
import Plan from './Plan';

// Styles
import './index.css';

// Constants
const DATA_FILE_NAME = 'plans.json';
const PLAN_GRID_COLUMN_WIDTH = 5;
const PLANS_GRID_CENTERED = true;
const PLANS_GRID_CONTAINER = true;
const PLANS_GRID_ROW_CENTERED = true;
const PLANS_GRID_STACKABLE = true;
const PLANS_GRID_VERTICAL_ALIGN = 'middle';

function Plans(props) {
  // State
  const [ plans, setPlans ] = useState({});

  // Hooks
  useEffect(() => {
    import('./../../../../data/' + DATA_FILE_NAME)
      .then(data => setPlans(data.plans));
  }, []);

  // Renderers
  function renderPlan(plan) {
    return (
      <Grid.Column
        className="PlanColumn"
        key={plan.id}
        width={PLAN_GRID_COLUMN_WIDTH}
      >
        <Plan
          data={{
            plan,
          }}
          handlers={props.handlers}
          texts={props.texts}
        />
      </Grid.Column>
    );
  }

  function renderPlans(plans) {
    return (
      <Grid.Row
        className="PlansRow"
        centered={PLANS_GRID_ROW_CENTERED}
      >
        {
          Object.keys(plans).map(planId => renderPlan(plans[planId]))
        }
      </Grid.Row>
    );
  }

  return (
    <div className="Plans">
      <Grid
        centered={PLANS_GRID_CENTERED}
        container={PLANS_GRID_CONTAINER}
        stackable={PLANS_GRID_STACKABLE}
        verticalAlign={PLANS_GRID_VERTICAL_ALIGN}
      >
        {
          plans
          && renderPlans(plans)
        }
      </Grid>
    </div>
  );
}

export default Plans;
