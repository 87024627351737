// NPM Packages
import { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Input,
  Segment,
} from 'semantic-ui-react';

// Styles
import './index.css';

// Constants
const BUTTON_UPDATE_USER_SESSION_MESSENGER_ID_COLOR = 'primary';
const BUTTON_UPDATE_USER_SESSION_MESSENGER_ID_ICON_NAME = 'save';
const HEADER_USER_SESSION_MESSENGER_ID_SIZE = 'medium';
const USER_SESSION_MESSENGER_ID_COPIED_BUTTON_ICON_NAME = 'checkmark';
const USER_SESSION_MESSENGER_ID_COPY_BUTTON_COLOR = 'blue';
const USER_SESSION_MESSENGER_ID_COPY_BUTTON_ICON_NAME = 'copy';
const USER_SESSION_MESSENGER_ID_COPY_BUTTON_LABEL_POSITION = 'right';
const USER_SESSION_MESSENGER_ID_COPY_BUTTON_READ_ONLY = true;

function ReadUserSessionMessengerId(props) {
  // State
  const [ isCopied, setIsCopied ] = useState(false);

  // Hooks
  useEffect(() => {
    if (
      props.data
      && props.data.token
      && !props.data.fetching
      && !props.data.fetched
    ) {
      props.handlers.readUserSessionMessengerId(props.data.token);
    }
  }, [
    props.data,
    props.data.token,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
  ]);

  // Handlers
  function handleCopyToClipboard(event) {
    // We have a component made of an input, a button and an icon.
    let sessionMessengerId;
    // If the user clicks on the button,
    if (event.target.localName === 'button') {
      // we need to get the value of the input (the parent).
      sessionMessengerId = event.target.parentNode
        && event.target.parentNode.firstChild
        && event.target.parentNode.firstChild.value;
    }
    // Else if the user clicks on the icon,
    else if (event.target.localName === 'i') {
      // we need to get the value of the input (the grand-parent).
      sessionMessengerId = event.target.parentNode
        && event.target.parentNode.parentNode
        && event.target.parentNode.parentNode.firstChild
        && event.target.parentNode.parentNode.firstChild.value;
    }

    if (sessionMessengerId) {
      navigator.clipboard.writeText(sessionMessengerId)
        .then(response => {
          //console.log('response: ', response);

          setIsCopied(true);
        })
        .catch(error => {
          //console.log('error: ', error);

          setIsCopied(false);
        });
    }
  }

  // Renderers
  function renderUpdateUserSessionMessengerIdButton() {
    return (
      <Button
        color={BUTTON_UPDATE_USER_SESSION_MESSENGER_ID_COLOR}
        onClick={props.handlers.openPopupUpdateSessionMessengerId}
      >
        <Icon name={BUTTON_UPDATE_USER_SESSION_MESSENGER_ID_ICON_NAME} />
        { props.texts['USER_UPDATE_SESSION_MESSENGER_ID_BUTTON_UPDATE_LABEL'] }
      </Button>
    );
  }

  function renderUserSessionMessengerIdCopyButton(sessionMessengerId) {
    let isDisabled;
    if (sessionMessengerId) {
      isDisabled = false;
    }
    else {
      isDisabled = true;
    }

    let contentLabel = isCopied ?
      props.texts['ACCOUNT_SESSION_MESSENGER_ID_COPIED_BUTTON_LABEL'] :
      props.texts['ACCOUNT_SESSION_MESSENGER_ID_COPY_BUTTON_LABEL'];

    let iconName = isCopied ?
      USER_SESSION_MESSENGER_ID_COPIED_BUTTON_ICON_NAME :
      USER_SESSION_MESSENGER_ID_COPY_BUTTON_ICON_NAME;

    return (
      <>
        <Input
          action={{
            color: USER_SESSION_MESSENGER_ID_COPY_BUTTON_COLOR,
            content: contentLabel,
            disabled: isDisabled,
            icon: iconName,
            labelPosition: USER_SESSION_MESSENGER_ID_COPY_BUTTON_LABEL_POSITION,
            loading: props.data.fetching,
            onClick: handleCopyToClipboard,
          }}
          readOnly={USER_SESSION_MESSENGER_ID_COPY_BUTTON_READ_ONLY}
          value={sessionMessengerId}
        />
      </>
    );
  }

  return (
    <div className="ReadUserSessionMessengerId">
      <Segment>
        <Header size={HEADER_USER_SESSION_MESSENGER_ID_SIZE}>
          { props.texts['ACCOUNT_HEADER_SESSION_MESSENGER_ID_LABEL'] }
        </Header>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="center" width={16}>
              {
                props.data
                && props.data.currentUser
                && renderUpdateUserSessionMessengerIdButton()
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center" width={16}>
              {
                props.data
                && props.data.currentUser
                && renderUserSessionMessengerIdCopyButton(
                  props.data.currentUser.sessionMessengerId
                )
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
}

export default ReadUserSessionMessengerId;
