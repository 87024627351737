// NPM Packages
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Grid,
} from 'semantic-ui-react';

// Styles
import './index.css';

// Constants
const MARKDOWN_FILE_NAME = 'contact.md';

function Contact(props) {
  // State
  const [ markdown, setMarkdown ] = useState('');

  // Hooks
  useEffect(() => {
    import('./../../../markdowns/'+ MARKDOWN_FILE_NAME)
      .then(module => {
        fetch(module.default)
          .then(res => res.text())
          .then(text => setMarkdown(text));
      });
  }, []);

  return (
    <div className="Contact">
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={16}>
            <ReactMarkdown children={markdown} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Contact;
