// NPM Packages
import { useState } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
} from 'semantic-ui-react'

// Custom Modules
import Notice from './../../Notice';

// Styles
import './index.css';

// Constants
const FORM_BUTTON_CANCEL_ICON_NAME = 'cancel';
const FORM_BUTTON_UPDATE_ICON_NAME = 'save';
const FORM_FIELD_SESSION_MESSENGER_ID_TYPE = 'text';

function UpdateUserSessionMessengerId(props) {
  // State
  const [ sessionMessengerId, setSessionMessengerId ] = useState('');

  // Handlers
  function handlePressEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (isFormComplete() && !props.data.fetching) {
        props.handlers.updateUserSessionMessengerId(
          props.data.token,
          sessionMessengerId
        );
      }
    }
  }

  function handleUpdateSessionMessengerId(event) {
    setSessionMessengerId(event.target.value);
  }

  // Helpers
  function isFormComplete() {
    let result = false;

    if (sessionMessengerId) {
      result = true;
    }

    return result;
  }

  // Renderers
  function renderNotice(notice) {
    return (
      <div className="Notice">
        <Notice
          data={{
            notice,
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Modal.Header className="UpdateUserSessionMessengerId">
        <div className="ModalHeader">
          {
            props.texts['USER_UPDATE_SESSION_MESSENGER_ID_MODAL_HEADER_LABEL']
          }
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>
                { props.texts['USER_UPDATE_FIELD_SESSION_MESSENGER_ID_LABEL'] }
              </label>
              <Input
                onChange={handleUpdateSessionMessengerId}
                onKeyPress={handlePressEnterKey}
                placeholder={
                  props
                    .texts['USER_UPDATE_FIELD_SESSION_MESSENGER_ID_PLACEHOLDER']
                }
                type={FORM_FIELD_SESSION_MESSENGER_ID_TYPE}
                value={sessionMessengerId}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={props.data.fetching}
          onClick={props.handlers.closePopup}
          secondary
        >
          <Icon name={FORM_BUTTON_CANCEL_ICON_NAME} />
          {
            props.texts['USER_UPDATE_SESSION_MESSENGER_ID_BUTTON_CANCEL_LABEL']
          }
        </Button>
        <Button
          disabled={!isFormComplete() || props.data.fetching}
          loading={props.data.fetching}
          onClick={() => {
            return props.handlers.updateUserSessionMessengerId(
              props.data.token,
              sessionMessengerId
            );
          }}
          primary
        >
        <Icon name={FORM_BUTTON_UPDATE_ICON_NAME} />
          { props.texts['USER_UPDATE_SESSION_MESSENGER_ID_BUTTON_UPDATE_LABEL'] }
        </Button>
      </Modal.Actions>
      {
        props.data.notice
        && renderNotice(props.data.notice)
      }
    </>
  );
}

export default UpdateUserSessionMessengerId;
