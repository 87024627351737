// NPM Packages
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
} from 'semantic-ui-react'

// Custom Modules
import { formatAmount } from './../../../helpers';
import Notice from './../../Notice';

// Styles
import './index.css';

// Constants
const FORM_FIELD_PRICE_TYPE = 'number';
const FORM_FIELD_AMOUNT_TYPE = 'number';
const FORM_FIELD_ADDRESS_TYPE = 'text';
const FORM_BUTTON_CANCEL_ICON_NAME = 'cancel';
const FORM_BUTTON_CREATE_COLOR = 'orange';
const FORM_BUTTON_CREATE_ICON_NAME = 'monero';
const POPUP_CLOSE_TIMEOUT = process.env.REACT_APP_POPUP_CLOSE_TIMEOUT;

function CreatePayment(props) {
  // State
  const [ payment, setPayment ] = useState({
    price: process.env.REACT_APP_PLANS_AND_PRICING_PREMIUM_PRICE,
    amount: (
      props.data.payment
      && props.data.payment.amount
      && formatAmount(props.data.payment.amount)
    ) || '',
    address: (props.data.payment && props.data.payment.address) || '',
  });

  // Hooks
  useEffect(() => {
    if (props.data.payment) {
      setPayment({
        ...payment,
        amount: formatAmount(props.data.payment.amount),
        address: props.data.payment.address,
      });
    }
  }, [
    payment,
    props.data.payment,
  ]);

  useEffect(() => {
    if (
      props.data.user
      && props.data.user.id
      && props.data.payment
      && props.data.payment.id
      && props.data.payment.amount
      && props.data.payment.address
      && !props.data.payment.isReceived
      && !props.data.payment.isListenedTo
      && !props.data.fetching
      && props.data.fetched
    ) {
      props.handlers.updatePayment(props.data.token, {
        ...props.data.payment,
        isListenedTo: true,
      });
    }
  }, [
    props.data.user,
    props.data.payment,
    props.data.token,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
    props.handlers.updatePayment,
  ]);

  useEffect(() => {
    if (
      props.data.payment
      && props.data.payment.isReceived
    ) {
      setTimeout(() => {
        props.handlers.closePopup();
      }, POPUP_CLOSE_TIMEOUT);
    }
  }, [
    props.data,
    props.data.payment,
    props.handlers,
    props.handlers.closePopup,
  ]);

  // Helpers
  function isFormComplete() {
    let result = false;

    if (payment.price) {
      result = true;
    }

    return result;
  }

  function renderNotice(notice) {
    return (
      <div className="Notice">
        <Notice
          data={{
            notice,
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Modal.Header className="CreatePayment">
        <div className="ModalHeader">
          { props.texts['PAYMENTS_CREATE_MODAL_HEADER_LABEL'] }
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>
                { props.texts['PAYMENTS_CREATE_FIELD_PRICE_LABEL'] }
              </label>
              <Input
                disabled
                readOnly
                type={FORM_FIELD_PRICE_TYPE}
                value={payment.price}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['PAYMENTS_CREATE_FIELD_AMOUNT_LABEL'] }
              </label>
              <Input
                readOnly
                type={FORM_FIELD_AMOUNT_TYPE}
                value={payment.amount}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['PAYMENTS_CREATE_FIELD_ADDRESS_LABEL'] }
              </label>
              <Input
                readOnly
                type={FORM_FIELD_ADDRESS_TYPE}
                value={payment.address}
              />
            </Form.Field>
        </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={props.data.fetching}
          onClick={props.handlers.closePopup}
          secondary
        >
          <Icon name={FORM_BUTTON_CANCEL_ICON_NAME} />
          { props.texts['PAYMENTS_CREATE_BUTTON_CANCEL_LABEL'] }
        </Button>
        <Button
          color={FORM_BUTTON_CREATE_COLOR}
          disabled={
            !isFormComplete()
            || props.data.fetching
            || props.data.fetched
          }
          loading={props.data.fetching}
          onClick={() => props.handlers.createPayment(props.data.token, payment)}
        >
          <Icon name={FORM_BUTTON_CREATE_ICON_NAME} />
          { props.texts['PAYMENTS_CREATE_BUTTON_CREATE_LABEL'] }
        </Button>
      </Modal.Actions>
      {
        props.data.notice
        && renderNotice(props.data.notice)
      }
    </>
  );
}

export default CreatePayment;
