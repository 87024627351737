/*
 * Auth
 */
// Auth Operations
const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

/*
 * Payments
 */
// CRUD Operations
const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';
const LIST_PAYMENTS_REQUEST = 'LIST_PAYMENTS_REQUEST';
const LIST_PAYMENTS_SUCCESS = 'LIST_PAYMENTS_SUCCESS';
const LIST_PAYMENTS_FAILURE = 'LIST_PAYMENTS_FAILURE';
const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';

/*
 * User
 */
// CRUD Operations
const READ_USER_SESSION_MESSENGER_ID_REQUEST = 'READ_USER_SESSION_MESSENGER_ID_REQUEST';
const READ_USER_SESSION_MESSENGER_ID_SUCCESS = 'READ_USER_SESSION_MESSENGER_ID_SUCCESS';
const READ_USER_SESSION_MESSENGER_ID_FAILURE = 'READ_USER_SESSION_MESSENGER_ID_FAILURE';
const UPDATE_USER_SESSION_MESSENGER_ID_REQUEST = 'UPDATE_USER_SESSION_MESSENGER_ID_REQUEST';
const UPDATE_USER_SESSION_MESSENGER_ID_SUCCESS = 'UPDATE_USER_SESSION_MESSENGER_ID_SUCCESS';
const UPDATE_USER_SESSION_MESSENGER_ID_FAILURE = 'UPDATE_USER_SESSION_MESSENGER_ID_FAILURE';

/*
 * Users
 */
// CRUD Operations
const LIST_USERS_REQUEST = 'LIST_USERS_REQUEST';
const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
const LIST_USERS_FAILURE = 'LIST_USERS_FAILURE';
const READ_USER_REQUEST = 'READ_USER_REQUEST';
const READ_USER_SUCCESS = 'READ_USER_SUCCESS';
const READ_USER_FAILURE = 'READ_USER_FAILURE';
const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
const SELECT_USER = 'SELECT_USER';

/*
 * Videos
 */
// CRUD Operations
const CREATE_VIDEO_REQUEST = 'CREATE_VIDEO_REQUEST';
const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS';
const CREATE_VIDEO_FAILURE = 'CREATE_VIDEO_FAILURE';
const LIST_VIDEOS_REQUEST = 'LIST_VIDEOS_REQUEST';
const LIST_VIDEOS_SUCCESS = 'LIST_VIDEOS_SUCCESS';
const LIST_VIDEOS_FAILURE = 'LIST_VIDEOS_FAILURE';
const READ_VIDEO_REQUEST = 'READ_VIDEO_REQUEST';
const READ_VIDEO_SUCCESS = 'READ_VIDEO_SUCCESS';
const READ_VIDEO_FAILURE = 'READ_VIDEO_FAILURE';
const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST';
const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS';
const UPDATE_VIDEO_FAILURE = 'UPDATE_VIDEO_FAILURE';
const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE';
const SELECT_VIDEO = 'SELECT_VIDEO';

/*
 * UI
 */
const OPEN_POPUP = 'OPEN_POPUP';
const CLOSE_POPUP = 'CLOSE_POPUP';
const RESET_CAPTCHA = 'RESET_CAPTCHA';
const RESET_PAGE = 'RESET_PAGE';

export {
  /*
   * Auth
   */
  // Auth Operations
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,

  /*
   * Payments
   */
  // CRUD Operations
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  LIST_PAYMENTS_REQUEST,
  LIST_PAYMENTS_SUCCESS,
  LIST_PAYMENTS_FAILURE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,

  /*
   * User
   */
  // CRUD Operations
  READ_USER_SESSION_MESSENGER_ID_REQUEST,
  READ_USER_SESSION_MESSENGER_ID_SUCCESS,
  READ_USER_SESSION_MESSENGER_ID_FAILURE,
  UPDATE_USER_SESSION_MESSENGER_ID_REQUEST,
  UPDATE_USER_SESSION_MESSENGER_ID_SUCCESS,
  UPDATE_USER_SESSION_MESSENGER_ID_FAILURE,

  /*
   * Users
   */
  // CRUD Operations
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILURE,
  READ_USER_REQUEST,
  READ_USER_SUCCESS,
  READ_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SELECT_USER,

  /*
   * Videos
   */
  // CRUD Operations
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAILURE,
  LIST_VIDEOS_REQUEST,
  LIST_VIDEOS_SUCCESS,
  LIST_VIDEOS_FAILURE,
  READ_VIDEO_REQUEST,
  READ_VIDEO_SUCCESS,
  READ_VIDEO_FAILURE,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE,
  SELECT_VIDEO,

  /*
   * UI
   */
  OPEN_POPUP,
  CLOSE_POPUP,
  RESET_CAPTCHA,
  RESET_PAGE,
};
