// NPM Packages
import { useEffect } from 'react';
import {
  Modal,
} from 'semantic-ui-react';

// Custom Modules
import Notice from './../../Notice';

// Styles
import './index.css';

// Constants
const POPUP_CLOSE_TIMEOUT = process.env.REACT_APP_POPUP_CLOSE_TIMEOUT;

function ConfirmEmail(props) {
  // Automatically Confirm Email
  useEffect(() => {
    if (
      props.token
      && !props.data.fetching
      && !props.data.fetched
    ) {
      props.handlers.confirmEmail(props.token);
    }
  }, [
    props.token,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
  ]);

  // Automatically Close Popup
  useEffect(() => {
    if (!props.data.fetching && props.data.fetched) {
      setTimeout(() => {
        props.handlers.closePopup();
      }, POPUP_CLOSE_TIMEOUT);
    }
  }, [
    props.data,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
    props.handlers.closePopup,
  ]);

  // Renderers
  function renderNotice(notice) {
    return (
      <div className="Notice">
        <Notice
          data={{
            notice,
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Modal.Header>
        { props.texts['AUTH_MODAL_HEADER_CONFIRM_EMAIL_LABEL'] }
      </Modal.Header>
      {
        props.data.notice
        && renderNotice(props.data.notice)
      }
    </>
  );
}

export default ConfirmEmail;
