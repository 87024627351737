// NPM Packages
import axios from 'axios';

// Custom Modules
import {
  // CRUD Operations
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILURE,
  READ_USER_REQUEST,
  READ_USER_SUCCESS,
  READ_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SELECT_USER,
} from './../actionTypes';

// CRUD Operations
function listUsersRequest(token, pagination, dispatch) {
  dispatch({
    type: LIST_USERS_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/users';
  axios.get(url, {
    headers: {
      'x-auth': token,
    },
    params: {
      ...pagination,
    }
  })
    .then(response => {
      dispatch(
        listUsersSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        listUsersFailure(error)
      );
    });
}

function listUsersSuccess(data) {
  return {
    payload: data,
    type: LIST_USERS_SUCCESS,
  };
}

function listUsersFailure(data) {
  return {
    payload: data,
    type: LIST_USERS_FAILURE,
  };
}

function readUserRequest(token, userId, dispatch) {
  dispatch({
    type: READ_USER_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/users/' + userId;
  axios.get(url, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        readUserSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        readUserFailure(error)
      );
    });
}

function readUserSuccess(data) {
  return {
    payload: data,
    type: READ_USER_SUCCESS,
  };
}

function readUserFailure(data) {
  return {
    payload: data,
    type: READ_USER_FAILURE,
  };
}

function updateUserRequest(token, user, dispatch) {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/users/' + user.id;
  axios.patch(url, {
    ...user
  }, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        updateUserSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        updateUserFailure(error.response.data)
      );
    });
}

function updateUserSuccess(data) {
  return {
    payload: data,
    type: UPDATE_USER_SUCCESS,
  };
}

function updateUserFailure(data) {
  return {
    payload: data,
    type: UPDATE_USER_FAILURE,
  };
}

function deleteUserRequest(token, userId, dispatch) {
  dispatch({
    type: DELETE_USER_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/users/' + userId;
  axios.delete(url, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        deleteUserSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        deleteUserFailure(error)
      );
    });
}

function deleteUserSuccess(data) {
  return {
    payload: data,
    type: DELETE_USER_SUCCESS,
  };
}

function deleteUserFailure(data) {
  return {
    payload: data,
    type: DELETE_USER_FAILURE,
  };
}

function selectUser(userId, dispatch) {
  dispatch({
    payload: userId,
    type: SELECT_USER,
  });
}

export {
  // CRUD Operations
  listUsersRequest,
  listUsersSuccess,
  listUsersFailure,
  readUserRequest,
  readUserSuccess,
  readUserFailure,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  selectUser,
};
