// Custom Modules
import ResetPassword from './../components/auth/ResetPassword';
import Layout from './../components/Layout';

function ResetPasswordPage(props) {
  return (
    <div className="ResetPasswordPage">
      <Layout>
        <ResetPassword />
      </Layout>
    </div>
  );
}

export default ResetPasswordPage;
