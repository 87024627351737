// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  readVideoRequest,
  selectVideo,
} from './../store/actions';
import { VideoPage } from './../pages';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {
      ...ownProps.data,

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * User
       */
      currentUser: state.user.current,

      /*
       * Videos
       */
      activeVideo: state.videos.items[state.videos.activeId],
      fetching: state.videos.fetching,
      fetched: state.videos.fetched,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Videos
       */
      readVideo: (token) => readVideoRequest(token, dispatch),
      selectVideo: (videoId) => selectVideo(videoId, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);
