export default {
  ACCOUNT_HEADER_ACCOUNT_LABEL: "Account",
  ACCOUNT_HEADER_SESSION_MESSENGER_ID_LABEL: "Session Messenger ID",
  ACCOUNT_SESSION_MESSENGER_ID_COPIED_BUTTON_LABEL: "Copied!",
  ACCOUNT_SESSION_MESSENGER_ID_COPY_BUTTON_LABEL: "Copy",
  ADMIN_TYPE_LABEL: "ADMIN",
  AUTH_BUTTON_CANCEL_LABEL: "Cancel",
  AUTH_BUTTON_CHANGE_PASSWORD_LABEL: "Change Password",
  AUTH_BUTTON_RESET_PASSWORD_LABEL: "Reset Password",
  AUTH_BUTTON_SIGN_IN_LABEL: "Log In",
  AUTH_BUTTON_SIGN_OUT_LABEL: "Logout",
  AUTH_BUTTON_SIGN_UP_LABEL: "Register",
  AUTH_BUTTON_SIGN_UP_DISABLED_POPUP_LABEL: "Registration has been disabled temporarily.",
  AUTH_FIELD_EMAIL_LABEL: "Email",
  AUTH_FIELD_EMAIL_PLACEHOLDER: "Email",
  AUTH_FIELD_PASSWORD_LABEL: "Password",
  AUTH_FIELD_PASSWORD_PLACEHOLDER: "Password",
  AUTH_FIELD_PASSWORD_CONFIRMATION_LABEL: "Password Confirmation",
  AUTH_FIELD_PASSWORD_CONFIRMATION_PLACEHOLDER: "Password Confirmation",
  AUTH_LINK_FORGOT_PASSWORD_LABEL: "Forgot Password?",
  AUTH_LINK_DONT_HAVE_ACCOUNT_LABEL: "Don't have an account yet?",
  AUTH_MODAL_HEADER_CHANGE_PASSWORD_LABEL: "Change Password",
  AUTH_MODAL_HEADER_CONFIRM_EMAIL_LABEL: "Confirm Email",
  AUTH_MODAL_HEADER_RESET_PASSWORD_LABEL: "Reset Password",
  AUTH_MODAL_HEADER_SIGN_IN_LABEL: "Log In",
  AUTH_MODAL_HEADER_SIGN_UP_LABEL: "Register",
  "BUTTON_MORE_LABEL": "Learn More",
  FOOTER_MESSAGE_TITLE: "GTWY Academy",
  FOOTER_MESSAGE_DESCRIPTION: "Learn how to use private-by-default cryptocurrencies, write Private Smart Contracts and build Secure Decentralized Applications (SDApps).",
  FOOTER_PAGES_BOOK_ME_LABEL: "Book Me",
  FOOTER_PAGES_CODE_LABEL: "Code",
  FOOTER_PAGES_CONTACT_LABEL: "Contact",
  FOOTER_PAGES_HEADER_LABEL: "Pages",
  FOOTER_PAGES_HOME_LABEL: "GTWY Academy",
  FOOTER_PAGES_PAYMENTS_LABEL: "Payments",
  FOOTER_PAGES_PLANS_AND_PRICING_LABEL: "Plans & Pricing",
  FOOTER_PAGES_VIDEOS_LABEL: "Videos",
  FOOTER_SOCIALS_HEADER_LABEL: "Socials",
  GET_STARTED_BUTTON_SIGN_IN_LABEL: "Log In",
  GET_STARTED_BUTTON_SIGN_UP_LABEL: "Register",
  GET_STARTED_MODAL_HEADER_GET_STARTED_LABEL: "Get Started",
  HEADER_FIXED_MENU_BUTTON_SIGN_IN_LABEL: "Log In",
  HEADER_FIXED_MENU_BUTTON_SIGN_UP_LABEL: "Register",
  HEADER_LINK_ACCOUNT_LABEL: "Account",
  HEADER_LINK_BOOK_ME_LABEL: "Book Me",
  HEADER_LINK_CODE_LABEL: "Code",
  HEADER_LINK_CONTACT_LABEL: "Contact",
  HEADER_LINK_HOME_LABEL: "GTWY Academy",
  HEADER_LINK_PAYMENTS_LABEL: "Payments",
  HEADER_LINK_PLANS_AND_PRICING_LABEL: "Plans & Pricing",
  HEADER_LINK_USERS_LABEL: "Users",
  HEADER_LINK_VIDEOS_LABEL: "Videos",
  HEADER_TOP_MENU_BUTTON_SIGN_IN_LABEL: "Log In",
  HEADER_TOP_MENU_BUTTON_SIGN_UP_LABEL: "Register",
  HEADER_UNFIXED_MENU_BUTTON_SIGN_IN_LABEL: "Log In",
  HEADER_UNFIXED_MENU_BUTTON_SIGN_UP_LABEL: "Register",
  HEADER_APP_NAME_LABEL: "GTWY Academy",
  HEADER_BUTTON_SIGN_IN_LABEL: "Log In",
  HEADER_BUTTON_SIGN_UP_LABEL: "Register",
  HERO_ACTION_BUTTON_LABEL: "Get Started",
  HERO_BASELINE_LABEL: "Learn how to use private-by-default cryptocurrencies, write Private Smart Contracts and build Secure Decentralized Applications (SDApps).",
  HERO_TITLE_LABEL: "GTWY Academy",
  HOME_DESKTOP_CONTAINER_BUTTON_SIGN_IN_LABEL: "Log In",
  HOME_DESKTOP_CONTAINER_BUTTON_SIGN_UP_LABEL: "Register",
  HOME_DESKTOP_CONTAINER_LINK_HOME_LABEL: "GTWY Academy",
  HOME_DESKTOP_CONTAINER_LINK_CODE_LABEL: "Code",
  HOME_DESKTOP_CONTAINER_LINK_VIDEOS_LABEL: "Videos",
  HOME_HEADING_BUTTON_GET_STARTED_LABEL: "Get Started",
  HOME_HEADING_HEADER_DESCRIPTION_TEXT: "Learn how to use private-by-default cryptocurrencies, write Private Smart Contracts and build Secure Decentralized Applications (SDApps).",
  HOME_HEADING_HEADER_LOGO_ALT: "GTWY Academy",
  HOME_FOOTING_DESCRIPTION_TEXT: "Learn how to use private-by-default cryptocurrencies, write Private Smart Contracts and build Secure Decentralized Applications (SDApps).",
  HOME_MOBILE_CONTAINER_BUTTON_SIGN_IN_LABEL: "Log In",
  HOME_MOBILE_CONTAINER_BUTTON_SIGN_UP_LABEL: "Register",
  HOME_MOBILE_CONTAINER_LINK_HOME_LABEL: "GTWY Academy",
  HOME_MOBILE_CONTAINER_LINK_CODE_LABEL: "Code",
  HOME_MOBILE_CONTAINER_LINK_VIDEOS_LABEL: "Videos",
  HOME_PAGE_DIVIDER_APP_NAME_LINK_LABEL: "GTWY Academy",
  HOME_PAGE_SECTION_MISSION_LINK_BUTTON_LABEL: "Get Started",
  HOME_PAGE_SECTION_MONERO_LINK_BUTTON_LABEL: "Check It Out",
  HOME_PAGE_SECTION_DERO_LINK_BUTTON_LABEL: "Check It Out",
  HOME_PAGE_FOOTING_ABOUT_HEADER_LABEL: "About",
  HOME_PAGE_FOOTING_BOOK_ME_LIST_ITEM_LABEL: "Book Me",
  HOME_PAGE_FOOTING_CONTACT_US_LIST_ITEM_LABEL: "Contact Us",
  HOME_PAGE_FOOTING_SERVICES_HEADER_LABEL: "Services",
  HOME_PAGE_FOOTING_FAQ_LIST_ITEM_LABEL: "FAQ",
  HOME_PAGE_FOOTING_APP_NAME_HEADER_LABEL: "GTWY Academy",
  LOADER_LABEL: "Loading ...",
  NOT_FOUND_LABEL: "404 - Page Not Found",
  NOTICE_ERROR_CONTENT_DEFAULT: "There was some errors with your submission.",
  NOTICE_ERROR_HEADER_DEFAULT: "Error!",
  NOTICE_INFO_CONTENT_DEFAULT: "Something you have to know.",
  NOTICE_INFO_HEADER_DEFAULT: "Info!",
  NOTICE_SUCCESS_CONTENT_DEFAULT: "Your submission was successful.",
  NOTICE_SUCCESS_HEADER_DEFAULT: "Success!",
  NOTICE_WARNING_CONTENT_DEFAULT: "Be mindful.",
  NOTICE_WARNING_HEADER_DEFAULT: "Warning!",
  PAYMENTS_CREATE_BUTTON_CANCEL_LABEL: "Cancel",
  PAYMENTS_CREATE_BUTTON_CREATE_LABEL: "Generate One-Time Payment Address",
  PAYMENTS_CREATE_FIELD_PRICE_LABEL: "Price ($)",
  PAYMENTS_CREATE_FIELD_AMOUNT_LABEL: "Amount (XMR)",
  PAYMENTS_CREATE_FIELD_ADDRESS_LABEL: "Address",
  PAYMENTS_CREATE_MODAL_HEADER_LABEL: "One-Time Payment ($149)",
  PAYMENTS_LIST_BUTTON_CREATE_PAYMENT_LABEL: "Create New Payment",
  PAYMENTS_LIST_PAYMENT_NONCE_LABEL: "Nonce",
  PAYMENTS_LIST_PAYMENT_PRICE_LABEL: "Price",
  PAYMENTS_LIST_PAYMENT_AMOUNT_LABEL: "Amount",
  PAYMENTS_LIST_PAYMENT_ADDRESS_LABEL: "Address",
  PAYMENTS_LIST_PAYMENT_IS_RECEIVED_LABEL: "Received?",
  PAYMENTS_LIST_PAYMENT_IS_CONFIRMED_LABEL: "Confirmed?",
  PAYMENTS_LIST_PAYMENT_IS_DATE_LABEL: "Date",
  PLANS_AND_PRICING_MODAL_HEADER_PLANS_AND_PRICING_LABEL: "Plans & Pricing",
  SIDEBAR_LINK_BOOK_ME_LABEL: "Book Me",
  SIDEBAR_LINK_CODE_LABEL: "Code",
  SIDEBAR_LINK_CONTACT_LABEL: "Contact",
  SIDEBAR_LINK_HOME_LABEL: "GTWY Academy",
  SIDEBAR_LINK_PAYMENTS_LABEL: "Payments",
  SIDEBAR_LINK_PLANS_AND_PRICING_LABEL: "Plans & Pricing",
  SIDEBAR_LINK_VIDEOS_LABEL: "Videos",
  SIDEBAR_MENU_BUTTON_SIGN_IN_LABEL: "Log In",
  SIDEBAR_MENU_BUTTON_SIGN_UP_LABEL: "Register",
  USER_UPDATE_SESSION_MESSENGER_ID_BUTTON_CANCEL_LABEL: "Cancel",
  USER_UPDATE_SESSION_MESSENGER_ID_BUTTON_UPDATE_LABEL: "Update Session Messenger ID",
  USER_UPDATE_FIELD_SESSION_MESSENGER_ID_LABEL: "Session Messenger ID",
  USER_UPDATE_FIELD_SESSION_MESSENGER_ID_PLACEHOLDER: "Your Session Messenger ID",
  USER_UPDATE_SESSION_MESSENGER_ID_MODAL_HEADER_LABEL: "Update Session Messenger ID",
  USER_TYPE_LABEL_FREEMIUM: "FREEMIUM",
  USER_TYPE_LABEL_PREMIUM: "PREMIUM",
  USER_TYPE_LABEL_CUSTOM: "CUSTOM",
  USERS_DELETE_BUTTON_CONFIRMATION_LABEL: "Delete User",
  USERS_LIST_USER_ID_LABEL: "ID",
  USERS_LIST_USER_EMAIL_LABEL: "Email",
  USERS_LIST_USER_SIGN_IN_COUNT_LABEL: "Login Count",
  USERS_LIST_USER_EMAIL_CONFIRMED_AT_LABEL: "Email Confirmed Date",
  USERS_LIST_USER_LOCKED_AT_LABEL: "Locked Date",
  USERS_LIST_USER_IS_ADMIN_LABEL: "Admin?",
  USERS_LIST_USER_IS_CONNECTED_LABEL: "Connected?",
  USERS_LIST_USER_IS_SUBSCRIBED_LABEL: "Subscribed?",
  USERS_LIST_USER_IS_SUBSCRIPTION_ACTIVE_LABEL: "Subscription Active?",
  USERS_LIST_USER_SUBSCRIPTION_EXPIRED_AT_LABEL: "Subscription Expired Date",
  USERS_LIST_USER_CREATED_AT_LABEL: "Created Date",
  USERS_LIST_USER_UPDATED_AT_LABEL: "Updated Date",
  USERS_READ_USER_ID_LABEL: "ID",
  USERS_READ_USER_EMAIL_LABEL: "Email",
  USERS_READ_USER_PASSWORD_CHANGE_COUNT_LABEL: "Password Change Count",
  USERS_READ_USER_PASSWORD_CHANGED_AT_LABEL: "Password Changed Date",
  USERS_READ_USER_PASSWORD_RESET_COUNT_LABEL: "Password Reset Count",
  USERS_READ_USER_PASSWORD_RESET_SENT_AT_LABEL: "Password Reset Sent Date",
  USERS_READ_USER_SIGN_IN_COUNT_LABEL: "Login Count",
  USERS_READ_USER_CURRENT_SIGN_IN_AT_LABEL: "Current Login Date",
  USERS_READ_USER_LAST_SIGN_IN_AT_LABEL: "Last Login Date",
  USERS_READ_USER_CURRENT_SIGN_IN_IP_LABEL: "Current Login IP",
  USERS_READ_USER_LAST_SIGN_IN_IP_LABEL: "Last Login IP",
  USERS_READ_USER_EMAIL_CONFIRMED_AT_LABEL: "Email Confirmed Date",
  USERS_READ_USER_EMAIL_CONFIRMATION_SENT_AT_LABEL: "Email Confirmation Sent Date",
  USERS_READ_USER_FAIL_ATTEMPTS_LABEL: "Fail Attempts",
  USERS_READ_USER_LOCKED_AT_LABEL: "Locked Date",
  USERS_READ_USER_IS_ADMIN_LABEL: "Admin?",
  USERS_READ_USER_IS_CONNECTED_LABEL: "Connected?",
  USERS_READ_USER_IS_SUBSCRIBED_LABEL: "Subscribed?",
  USERS_READ_USER_IS_SUBSCRIPTION_ACTIVE_LABEL: "Subscription Active?",
  USERS_READ_USER_SUBSCRIPTION_EXPIRED_AT_LABEL: "Subscription Expired Date",
  USERS_READ_USER_CREATED_AT_LABEL: "Created Date",
  USERS_READ_USER_UPDATED_AT_LABEL: "Update Date",
  USERS_UPDATE_USER_ID_LABEL: "ID",
  USERS_UPDATE_USER_EMAIL_LABEL: "Email",
  USERS_UPDATE_USER_PASSWORD_CHANGE_COUNT_LABEL: "Password Change Count",
  USERS_UPDATE_USER_PASSWORD_CHANGED_AT_LABEL: "Password Changed Date",
  USERS_UPDATE_USER_PASSWORD_RESET_COUNT_LABEL: "Password Reset Count",
  USERS_UPDATE_USER_PASSWORD_RESET_SENT_AT_LABEL: "Password Reset Sent Date",
  USERS_UPDATE_USER_SIGN_IN_COUNT_LABEL: "Login Count",
  USERS_UPDATE_USER_CURRENT_SIGN_IN_AT_LABEL: "Current Login Date",
  USERS_UPDATE_USER_LAST_SIGN_IN_AT_LABEL: "Last Login Date",
  USERS_UPDATE_USER_CURRENT_SIGN_IN_IP_LABEL: "Current Login IP",
  USERS_UPDATE_USER_LAST_SIGN_IN_IP_LABEL: "Last Login IP",
  USERS_UPDATE_USER_EMAIL_CONFIRMED_AT_LABEL: "Email Confirmed Date",
  USERS_UPDATE_USER_EMAIL_CONFIRMATION_SENT_AT_LABEL: "Email Confirmation Sent Date",
  USERS_UPDATE_USER_FAIL_ATTEMPTS_LABEL: "Fail Attempts",
  USERS_UPDATE_USER_LOCKED_AT_LABEL: "Locked Date",
  USERS_UPDATE_USER_IS_ADMIN_LABEL: "Admin?",
  USERS_UPDATE_USER_IS_CONNECTED_LABEL: "Connected?",
  USERS_UPDATE_USER_IS_SUBSCRIBED_LABEL: "Subscribed?",
  USERS_UPDATE_USER_IS_SUBSCRIPTION_ACTIVE_LABEL: "Subscription Active?",
  USERS_UPDATE_USER_SUBSCRIPTION_EXPIRED_AT_LABEL: "Subscription Expired Date",
  USERS_UPDATE_USER_CREATED_AT_LABEL: "Created Date",
  USERS_UPDATE_USER_UPDATED_AT_LABEL: "Update Date",
  USERS_UPDATE_BUTTON_CANCEL_LABEL: "Cancel",
  USERS_UPDATE_BUTTON_UPDATE_LABEL: "Update Existing User",
  USERS_UPDATE_MODAL_HEADER_LABEL: "Update User",
  VIDEO_TYPE_LABEL_FREEMIUM: "FREEMIUM",
  VIDEO_TYPE_LABEL_PREMIUM: "PREMIUM",
  VIDEO_TYPE_LABEL_CUSTOM: "CUSTOM",
  VIDEOS_CREATE_FIELD_INDEX_LABEL: "Index",
  VIDEOS_CREATE_FIELD_INDEX_PLACEHOLDER: "Index",
  VIDEOS_CREATE_FIELD_NAME_LABEL: "Name",
  VIDEOS_CREATE_FIELD_NAME_PLACEHOLDER: "Name",
  VIDEOS_CREATE_FIELD_DESCRIPTION_LABEL: "Description",
  VIDEOS_CREATE_FIELD_DESCRIPTION_PLACEHOLDER: "Description (190 characters max)",
  VIDEOS_CREATE_FIELD_DURATION_LABEL: "Duration",
  VIDEOS_CREATE_FIELD_DURATION_PLACEHOLDER: "Duration",
  VIDEOS_CREATE_FIELD_FILE_LABEL: "File",
  VIDEOS_CREATE_FIELD_FILE_PLACEHOLDER: "File",
  VIDEOS_CREATE_BUTTON_CANCEL_LABEL: "Cancel",
  VIDEOS_CREATE_BUTTON_CREATE_LABEL: "Create",
  VIDEOS_CREATE_MODAL_HEADER_LABEL: "Create Video",
  VIDEOS_DELETE_BUTTON_CONFIRMATION_LABEL: "Delete Video",
  VIDEOS_LIST_BUTTON_CREATE_PAYMENT_LABEL: "Create New Payment",
  VIDEOS_LIST_BUTTON_CREATE_VIDEO_LABEL: "Create New Video",
  VIDEOS_LIST_VIDEO_CREATED_AT_LABEL: "Created:",
  VIDEOS_LIST_VIDEO_DURATION_LABEL: "Duration:",
  VIDEOS_LIST_VIDEO_UPDATED_LABEL: "Updated:",
  VIDEOS_UPDATE_VIDEO_INDEX_LABEL: "Index",
  VIDEOS_UPDATE_VIDEO_INDEX_PLACEHOLDER: "Index",
  VIDEOS_UPDATE_VIDEO_NAME_LABEL: "Name",
  VIDEOS_UPDATE_VIDEO_NAME_PLACEHOLDER: "Name",
  VIDEOS_UPDATE_VIDEO_DESCRIPTION_LABEL: "Description",
  VIDEOS_UPDATE_VIDEO_DESCRIPTION_PLACEHOLDER: "Description (190 characters max)",
  VIDEOS_UPDATE_VIDEO_DURATION_LABEL: "Duration",
  VIDEOS_UPDATE_VIDEO_DURATION_PLACEHOLDER: "Duration",
  VIDEOS_UPDATE_VIDEO_FILE_LABEL: "File",
  VIDEOS_UPDATE_VIDEO_FILE_PLACEHOLDER: "File",
  VIDEOS_UPDATE_BUTTON_CANCEL_LABEL: "Cancel",
  VIDEOS_UPDATE_BUTTON_UPDATE_LABEL: "Update Existing Video",
  VIDEOS_UPDATE_MODAL_HEADER_LABEL: "Update Video"
};
