// NPM Packages
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Radio,
} from 'semantic-ui-react'

// Styles
import './index.css';

// Constants
const FORM_FIELD_ID_TYPE = 'text';
const FORM_FIELD_EMAIL_TYPE = 'text';
const FORM_FIELD_PASSWORD_CHANGE_COUNT_TYPE = 'number';
const FORM_FIELD_PASSWORD_CHANGED_AT_TYPE = 'text';
const FORM_FIELD_PASSWORD_RESET_COUNT_TYPE = 'number';
const FORM_FIELD_PASSWORD_RESET_SENT_AT_TYPE = 'text';
const FORM_FIELD_SIGN_IN_COUNT_TYPE = 'number';
const FORM_FIELD_CURRENT_SIGN_IN_AT_TYPE = 'text';
const FORM_FIELD_LAST_SIGN_IN_AT_TYPE = 'text';
const FORM_FIELD_CURRENT_SIGN_IN_IP_TYPE = 'text';
const FORM_FIELD_LAST_SIGN_IN_IP_TYPE = 'text';
const FORM_FIELD_EMAIL_CONFIRMED_AT_TYPE = 'text';
const FORM_FIELD_EMAIL_CONFIRMATION_SENT_AT_TYPE = 'text';
const FORM_FIELD_FAILED_ATTEMPTS_TYPE = 'number';
const FORM_FIELD_LOCKED_AT_TYPE = 'text';
const FORM_FIELD_IS_ADMIN_TYPE = 'boolean';
const FORM_FIELD_IS_CONNECTED_TYPE = 'boolean';
const FORM_FIELD_SUBSCRIPTION_EXPIRED_AT_TYPE = 'text';
const FORM_FIELD_CREATED_AT_TYPE = 'text';
const FORM_FIELD_UPDATED_AT_TYPE = 'text';
const FORM_BUTTON_CANCEL_ICON_NAME = 'cancel';
const FORM_BUTTON_UPDATE_ICON_NAME = 'save';

function UpdateUser(props) {
  let {
    id,
    email,
    passwordChangeCount,
    passwordChangedAt,
    passwordResetCount,
    passwordResetSentAt,
    signInCount,
    currentSignInAt,
    lastSignInAt,
    currentSignInIp,
    lastSignInIp,
    emailConfirmedAt,
    emailConfirmationSentAt,
    failedAttempts,
    lockedAt,
    isAdmin,
    isConnected,
    isSubscribed,
    isSubscriptionActive,
    subscriptionExpiredAt,
    createdAt,
    updatedAt,
  } = props.user;

  // State
  const [ user, setUser ] = useState({
    id: id || '',
    email: email || '',
    passwordChangeCount: passwordChangeCount || 0,
    passwordChangedAt: passwordChangedAt || '',
    passwordResetCount: passwordResetCount || 0,
    passwordResetSentAt: passwordResetSentAt || '',
    signInCount: signInCount || 0,
    currentSignInAt: currentSignInAt || '',
    lastSignInAt: lastSignInAt || '',
    currentSignInIp: currentSignInIp || '',
    lastSignInIp: lastSignInIp || '',
    emailConfirmedAt: emailConfirmedAt || '',
    emailConfirmationSentAt: emailConfirmationSentAt || '',
    failedAttempts: failedAttempts || 0,
    lockedAt: lockedAt || '',
    isAdmin: isAdmin || false,
    isConnected: isConnected || false,
    isSubscribed: isSubscribed || false,
    isSubscriptionActive: isSubscriptionActive || false,
    subscriptionExpiredAt: subscriptionExpiredAt || '',
    createdAt: createdAt || '',
    updatedAt: updatedAt || '',
  });

  // Hooks
  useEffect(() => {
    if (!props.data.fetching && props.data.fetched) {
      props.handlers.closePopup();
    }
  }, [
    props.data,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
  ]);

  // Handlers
  function handleUpdateUser(key, value) {
    setUser({
      ...user,
      [key]: value,
    });
  }

  // Helpers
  function isFormComplete() {
    let result = false;

    if (
      user.isSubscribed
      || user.isSubscriptionActive
    ) {
      result = true;
    }

    return result;
  }

  return (
    <>
      <Modal.Header className="UpdateUser">
        <div className="ModalHeader">
          { props.texts['USERS_UPDATE_MODAL_HEADER_LABEL'] }
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_ID_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_ID_TYPE}
                value={user.id}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_EMAIL_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_EMAIL_TYPE}
                value={user.email}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_PASSWORD_CHANGE_COUNT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_PASSWORD_CHANGE_COUNT_TYPE}
                value={user.passwordChangeCount}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_PASSWORD_CHANGED_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_PASSWORD_CHANGED_AT_TYPE}
                value={user.passwordChangedAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_PASSWORD_RESET_COUNT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_PASSWORD_RESET_COUNT_TYPE}
                value={user.passwordResetCount}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_PASSWORD_RESET_SENT_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_PASSWORD_RESET_SENT_AT_TYPE}
                value={user.passwordResetSentAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_SIGN_IN_COUNT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_SIGN_IN_COUNT_TYPE}
                value={user.passwordResetSentAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_CURRENT_SIGN_IN_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_CURRENT_SIGN_IN_AT_TYPE}
                value={user.currentSignInAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_LAST_SIGN_IN_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_LAST_SIGN_IN_AT_TYPE}
                value={user.lastSignInAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_CURRENT_SIGN_IN_IP_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_CURRENT_SIGN_IN_IP_TYPE}
                value={user.currentSignInIp}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_LAST_SIGN_IN_IP_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_LAST_SIGN_IN_IP_TYPE}
                value={user.lastSignInIp}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_EMAIL_CONFIRMED_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_EMAIL_CONFIRMED_AT_TYPE}
                value={user.emailConfirmedAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_EMAIL_CONFIRMATION_SENT_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_EMAIL_CONFIRMATION_SENT_AT_TYPE}
                value={user.emailConfirmationSentAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_FAIL_ATTEMPTS_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_FAILED_ATTEMPTS_TYPE}
                value={user.failedAttempts}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_LOCKED_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_LOCKED_AT_TYPE}
                value={user.lockedAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_IS_ADMIN_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_IS_ADMIN_TYPE}
                value={user.isAdmin}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_IS_CONNECTED_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_IS_CONNECTED_TYPE}
                value={user.isConnected}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_IS_SUBSCRIBED_LABEL'] }
              </label>
              <Radio
                onChange={event => handleUpdateUser('isSubscribed', event.target.value)}
                toggle
                value={user.isSubscribed}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_IS_SUBSCRIPTION_ACTIVE'] }
              </label>
              <Radio
                onChange={event => handleUpdateUser('isSubscriptionActive', event.target.value)}
                toggle
                value={user.isSubscriptionActive}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_SUBSCRIPTION_EXPIRED_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_SUBSCRIPTION_EXPIRED_AT_TYPE}
                value={user.subscriptionExpiredAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_CREATED_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_CREATED_AT_TYPE}
                value={user.createdAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['USERS_UPDATE_USER_UPDATED_AT_LABEL'] }
              </label>
              <Input
                disabled
                type={FORM_FIELD_UPDATED_AT_TYPE}
                value={user.updatedAt}
              />
            </Form.Field>
        </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={props.data.fetching}
          onClick={props.handlers.closePopup}
          secondary
        >
          <Icon name={FORM_BUTTON_CANCEL_ICON_NAME} />
          { props.texts['USERS_UPDATE_BUTTON_CANCEL_LABEL'] }
        </Button>
        <Button
          disabled={!isFormComplete() || props.data.fetching}
          loading={props.data.fetching}
          onClick={() => props.handlers.updateUser(props.data.token, user)}
          primary
        >
        <Icon name={FORM_BUTTON_UPDATE_ICON_NAME} />
          { props.texts['USERS_UPDATE_BUTTON_UPDATE_LABEL'] }
        </Button>
      </Modal.Actions>
    </>
  );
}

export default UpdateUser;
