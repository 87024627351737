// NPM Packages
import { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  Route,
  Routes,
} from 'react-router-dom';
import { createMedia } from '@artsy/fresnel';

// Custom Modules
import {
  /*
   * Account
   */
  AccountPage,

  /*
   * Auth
   */
  SignUp,
  ConfirmEmailPage,
  ConfirmEmail,
  SignIn,
  ResetPassword,
  ChangePasswordPage,
  ChangePassword,

  /*
   * Get Started
   */
  GetStarted,

  /*
   * Payments
   */
  CreatePayment,
  PaymentsPage,

  /*
   * User
   */
  UpdateUserSessionMessengerId,

  /*
   * Users
   */
  UsersPage,
  UserPage,
  UpdateUser,

  /*
   * Videos
   */
  CreateVideo,
  VideosPage,
  VideoPage,
  UpdateVideo,
} from './../../containers';
import {
  /*
   * Website
   */
  BookMePage,
  ContactPage,
  HomePage,
  PlansAndPricingPage,
} from './../../pages/website';
import NotFound from './../NotFound';
import Popup from './../Popup';
import ResponsiveContainer from './../ResponsiveContainer';

// Texts
import texts from './../../texts.js';

// Styles
import './index.css';

// Constants
const DEFAULT_ACTIVE_PAGE = 'home';
const DEFAULT_POPUP_BODY = null;
const DEFAULT_POPUP_OPENED = false;
const DEFAULT_POPUP_TYPE = null;
//const DEFAULT_TEXTS = {};
const DEFAULT_TEXTS_LOADED = true; // false

// Media
const { Media, MediaContextProvider } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

function App(props) {
  // State
  const [ activePage, setActivePage ] = useState('home');
  const [ popupBody, setPopupBody ] = useState(DEFAULT_POPUP_BODY);
  const [ popupOpened, setPopupOpened ] = useState(DEFAULT_POPUP_OPENED);
  const [ popupType, setPopupType ] = useState(DEFAULT_POPUP_TYPE);
  //const [ texts, setTexts ] = useState(DEFAULT_TEXTS);
  const [ textsLoaded, /*setTextsLoaded*/ ] = useState(DEFAULT_TEXTS_LOADED);

  // Navigate
  const navigate = useNavigate();

  //useEffect(() => {
  //    import('./../../texts.json')
  //      .then(texts => setTexts(texts))
  //      .finally(_ => setTextsLoaded(true));
  //}, []);

  // Location
  const location = useLocation();
  useEffect(() => {
    const locationPathname = location.pathname;

    const locationPathnameCleaned = locationPathname
      && locationPathname.split('/')[1];

    if (locationPathnameCleaned.length > 0) {
      setActivePage(locationPathnameCleaned);
    }
    else {
      setActivePage(DEFAULT_ACTIVE_PAGE);
    }

    return;
  }, [location.pathname]);

  // Handlers
  function openPopup(type, data = null) {
    const body = getPopupBody(type, data);

    setPopupOpened(true);
    setPopupType(type);
    setPopupBody(body);

    props.handlers.openPopup();
  }

  function closePopup(options = {}) {
    setPopupOpened(false);
    setPopupType(null);
    setPopupBody(null);

    if (options && options.shouldRedirect && options.redirectUri) {
      navigate(options.redirectUri);
    }
  }

  // Helpers
  function getPopupBody(type, data = null) {
    let result;

    switch (type) {
      /*
       * Auth
       */
      case 'sign-up':
        result = (
          <SignUp
            handlers={{
              closePopup,
            }}
            texts={texts}
          />
        );

        break;
      case 'confirm-email':
        result = (
          <ConfirmEmail
            handlers={{
              closePopup: () => closePopup({
                redirectUri: '/',
                shouldRedirect: true,
              }),
            }}
            texts={texts}
            token={data && data.token}
          />
        );

        break;
      case 'sign-in':
        result = (
          <SignIn
            handlers={{
              closePopup,
              openPopupResetPassword: () => openPopup('reset-password'),
              openPopupSignUp: () => openPopup('sign-up'),
            }}
            texts={texts}
          />
        );

        break;
      case 'reset-password':
        result = (
          <ResetPassword
            handlers={{
              closePopup,
            }}
            texts={texts}
          />
        );

        break;
      case 'change-password':
        result = (
          <ChangePassword
            handlers={{
              closePopup: () => closePopup({
                redirectUri: '/',
                shouldRedirect: true,
              }),
            }}
            texts={texts}
            token={data && data.token}
          />
        );

        break;

      /*
       * Get Started
       */
      case 'get-started':
        result = (
          <GetStarted
            handlers={{
              closePopup,
              openPopupSignIn: () => openPopup('sign-in'),
              openPopupSignUp: () => openPopup('sign-up'),
            }}
            texts={texts}
          />
        );

        break;

      /*
       * Payments
       */
      case 'create-payment':
        result = (
          <CreatePayment
            handlers={{
              closePopup,
            }}
            texts={texts}
          />
        );

        break;

      /*
       * User
       */
      case 'update-session-messenger-id':
        result = (
          <UpdateUserSessionMessengerId
            handlers={{
              closePopup,
            }}
            texts={texts}
          />
        );

        break;

      /*
       * Users
       */
      case 'update-user':
        result = (
          <UpdateUser
            handlers={{
              closePopup,
            }}
            texts={texts}
            user={data && data.user}
          />
        );

        break;

      /*
       * Videos
       */
      case 'create-video':
        result = (
          <CreateVideo
            handlers={{
              closePopup,
            }}
            texts={texts}
          />
        );

        break;
      case 'update-video':
        result = (
          <UpdateVideo
            handlers={{
              closePopup,
            }}
            texts={texts}
            video={data && data.video}
          />
        );

        break;

      default:
        console.log("Unknown type: " + type);
    }

    return result;
  }

  return (
    <div className="App">
      <MediaContextProvider>
        <ResponsiveContainer
          data={{
            currentUser: props.data.currentUser,
          }}
          handlers={{
            openPopupGetStarted: () => openPopup('get-started'),
            openPopupSignIn: () => openPopup('sign-in'),
            openPopupSignUp: () => openPopup('sign-up'),
          }}
          activePage={activePage}
          texts={texts}
          Media={Media}
        >
          <Routes>
            <Route
              element={
                <ChangePasswordPage
                  handlers={{
                    openPopupChangePassword: token => openPopup('change-password', { token }),
                  }}
                  texts={texts}
                  textsLoaded={textsLoaded}
                />
              }
              exact
              path="/change-password/:token"
              strict
            />
            <Route
              element={
                <ConfirmEmailPage
                  handlers={{
                    openPopupConfirmEmail: token => openPopup('confirm-email', { token }),
                  }}
                  texts={texts}
                  textsLoaded={textsLoaded}
                />
              }
              exact
              path="/confirm-email/:token"
              strict
            />
            <Route
              element={
                <AccountPage
                  handlers={{
                    openPopupUpdateSessionMessengerId: () => {
                      return openPopup('update-session-messenger-id');
                    },
                    signOut: props.handlers.signOut,
                  }}
                  texts={texts}
                />
              }
              exact
              path='/account'
              strict
            />
            <Route
              element={
                <PaymentsPage
                  handlers={{
                    openPopupCreatePayment: () => openPopup('create-payment'),
                    openPopupResetPassword: () => openPopup('reset-password'),
                    openPopupSignIn: () => openPopup('sign-in'),
                    openPopupSignUp: () => openPopup('sign-up'),
                    signOut: props.handlers.signOut,
                  }}
                  texts={texts}
                />
              }
              exact
              path="/payments"
              strict
            />
            <Route
              element={
                <UserPage
                  handlers={{
                    openPopupResetPassword: () => openPopup('reset-password'),
                    openPopupSignIn: () => openPopup('sign-in'),
                    openPopupSignUp: () => openPopup('sign-up'),
                    openPopupUpdateUser: user => openPopup('update-user', { user }),
                    signOut: props.handlers.signOut,
                  }}
                  texts={texts}
                />
              }
              exact
              path="/users/:userId"
              strict
            />
            <Route
              element={
                <UsersPage
                  handlers={{
                    openPopupResetPassword: () => openPopup('reset-password'),
                    openPopupSignIn: () => openPopup('sign-in'),
                    openPopupSignUp: () => openPopup('sign-up'),
                    signOut: props.handlers.signOut,
                  }}
                  texts={texts}
                />
              }
              exact
              path="/users"
              strict
            />
            <Route
              element={
                <VideoPage
                  handlers={{
                    openPopupResetPassword: () => openPopup('reset-password'),
                    openPopupSignIn: () => openPopup('sign-in'),
                    openPopupSignUp: () => openPopup('sign-up'),
                    signOut: props.handlers.signOut,
                  }}
                  texts={texts}
                />
              }
              exact
              path="/videos/:videoId"
              strict
            />
            <Route
              element={
                <VideosPage
                  handlers={{
                    openPopupCreatePayment: () => openPopup('create-payment'),
                    openPopupCreateVideo: () => openPopup('create-video'),
                    openPopupResetPassword: () => openPopup('reset-password'),
                    openPopupSignIn: () => openPopup('sign-in'),
                    openPopupSignUp: () => openPopup('sign-up'),
                    openPopupUpdateVideo: video => openPopup('update-video', { video }),
                    signOut: props.handlers.signOut,
                  }}
                  texts={texts}
                />
              }
              exact
              path="/videos"
              strict
            />
            <Route
              element={
                <HomePage
                  handlers={{
                    openPopupCreatePayment: () => openPopup('create-payment'),
                    openPopupGetStarted: () => openPopup('get-started'),
                    openPopupResetPassword: () => openPopup('reset-password'),
                    openPopupSignIn: () => openPopup('sign-in'),
                    openPopupSignUp: () => openPopup('sign-up'),
                    signOut: props.handlers.signOut,
                  }}
                  texts={texts}
                />
              }
              exact
              path="/"
              strict
            />
            <Route
              element={
                <BookMePage
                  activePage={activePage}
                  texts={texts}
                />
              }
              exact
              path='/book-me'
              strict
            />
            <Route
              element={
                <ContactPage
                  activePage={activePage}
                  texts={texts}
                />
              }
              exact
              path='/contact'
              strict
            />
            <Route
              element={
                <PlansAndPricingPage
                  handlers={{
                    closePopup,
                    openPopupSignIn: () => openPopup('sign-in'),
                  }}
                  activePage={activePage}
                  texts={texts}
                />
              }
              exact
              path='/plans-and-pricing'
              strict
            />
            <Route
              element={<NotFound />}
              path="*"
            />
          </Routes>
          <Popup
            data={{
              body: popupBody,
              opened: popupOpened,
              type: popupType,
            }}
            handlers={{
              closePopup,
            }}
            Media={Media}
          />
        </ResponsiveContainer>
      </MediaContextProvider>
    </div>
  );
}

export default App;
