// NPM Packages
import { Message } from 'semantic-ui-react';

// Constants
const NOTICE_ERROR_TYPE = 'error';
const NOTICE_INFO_TYPE = 'info';
const NOTICE_SIZE = 'small';
const NOTICE_SUCCESS_TYPE = 'success';
const NOTICE_WARNING_TYPE = 'warning';

// Renderers
function renderNoticeError(props) {
  const {
    content,
    header,
  } = props.data.notice;

  return (
    <Message
      content={content || props.texts['NOTICE_ERROR_CONTENT_DEFAULT'] }
      error
      header={header || props.texts['NOTICE_ERROR_HEADER_DEFAULT'] }
      size={NOTICE_SIZE}
    />
  );
}

function renderNoticeInfo(props) {
  const {
    content,
    header,
  } = props.data.notice;

  return (
    <Message
      content={content || props.texts['NOTICE_INFO_CONTENT_DEFAULT'] }
      header={header || props.texts['NOTICE_INFO_HEADER_DEFAULT'] }
      info
      size={NOTICE_SIZE}
    />
  );
}

function renderNoticeSuccess(props) {
  const {
    content,
    header,
  } = props.data.notice;

  return (
    <Message
      content={content || props.texts['NOTICE_SUCCESS_CONTENT_DEFAULT'] }
      header={header || props.texts['NOTICE_SUCCESS_HEADER_DEFAULT'] }
      size={NOTICE_SIZE}
      success
    />
  );
}

function renderNoticeWarning(props) {
  const {
    content,
    header,
  } = props.data.notice;

  return (
    <Message
      content={content || props.texts['NOTICE_WARNING_CONTENT_DEFAULT'] }
      header={header || props.texts['NOTICE_WARNING_HEADER_DEFAULT'] }
      size={NOTICE_SIZE}
      warning
    />
  );
}

function Notice(props) {
  let notice = null;

  if (props.data && props.data.notice) {
    switch (props.data.notice.type) {
      case NOTICE_ERROR_TYPE:
        notice = renderNoticeError(props);

        break;
      case NOTICE_INFO_TYPE:
        notice = renderNoticeInfo(props);

        break;
      case NOTICE_SUCCESS_TYPE:
        notice = renderNoticeSuccess(props);

        break;
      case NOTICE_WARNING_TYPE:
        notice = renderNoticeWarning(props);

        break;
      default:
        console.error('Unknown Notice Error Type: ', props.data.notice.type);
    }
  }

  return notice;
}

export default Notice;
