// NPM Packages
import { useEffect, useState } from 'react';
import {
  Segment,
  Visibility,
} from 'semantic-ui-react'

// Custom Modules
import Footing from './../../Footing';
import Hero from './../../Hero';
import TopMenu from './TopMenu';

// Styles
import './index.css';

// Constants
const FOOTER_INVERTED = true;
const FOOTER_VERTICAL = true;
const HEADER_SEGMENT_UNFIXED_INVERTED = true;
const HEADER_SEGMENT_UNFIXED_TEXT_ALIGN = 'center';
const HEADER_SEGMENT_UNFIXED_VERTICAL = true;
const HEADER_VIDEO_BACKGROUND_HEIGHT = '1080';
const HEADER_VIDEO_BACKGROUND_PRELOAD = 'metadata';
const HEADER_VIDEO_BACKGROUND_SRC_MP4 = 'videos/hero.mp4';
const HEADER_VIDEO_BACKGROUND_SRC_WEBM = 'videos/hero.webm';
const HEADER_VIDEO_BACKGROUND_THUMBNAIL_SRC = 'videos/thumbnails/hero.jpg';
const HEADER_VIDEO_BACKGROUND_TYPE_MP4 = 'video/mp4';
const HEADER_VIDEO_BACKGROUND_TYPE_WEBM = 'video/webm';
const VISIBILITY_ONCE = false;

function DesktopContainer(props) {
  // Media
  const Media = props.Media;

  // State
  const [ isMenuTopFixed, setIsMenuTopFixed ] = useState(
    (!props.activePage || props.activePage === 'home') ? false : true
  );

  // Hooks
  useEffect(() => {
    if (props.activePage === 'home') {
      setIsMenuTopFixed(false);
    }
    else {
      setIsMenuTopFixed(true);
    }
  }, [props.activePage]);

  // Handlers
  function hideTopFixedMenu() {
    setIsMenuTopFixed(false);
  }

  function showTopFixedMenu() {
    setIsMenuTopFixed(true);
  }

  // Renderers
  function renderConditionalHeader() {
    let header;

    if (props.activePage === 'home') {
      header = (
        <Segment
          className="UnfixedHeaderSegment"
          inverted={HEADER_SEGMENT_UNFIXED_INVERTED}
          style={{
            opacity: isMenuTopFixed ? null : 1,
          }}
          textAlign={HEADER_SEGMENT_UNFIXED_TEXT_ALIGN}
          vertical={HEADER_SEGMENT_UNFIXED_VERTICAL}
        >
          <div className="HeaderVideo">
            <video
              autoPlay
              height={HEADER_VIDEO_BACKGROUND_HEIGHT}
              loop
              muted
              playsInline
              poster={HEADER_VIDEO_BACKGROUND_THUMBNAIL_SRC}
              preload={HEADER_VIDEO_BACKGROUND_PRELOAD}
            >
              <source
                src={HEADER_VIDEO_BACKGROUND_SRC_WEBM}
                type={HEADER_VIDEO_BACKGROUND_TYPE_WEBM}
              />
              <source
                src={HEADER_VIDEO_BACKGROUND_SRC_MP4}
                type={HEADER_VIDEO_BACKGROUND_TYPE_MP4}
              />
            </video>
          </div>
          <div className="HeaderHero">
            <Hero
              data={{
                currentUser: props.data.currentUser,
              }}
              handlers={{
                openPopupGetStarted: props.handlers.openPopupGetStarted,
                openPopupSignIn: props.handlers.openPopupSignIn,
              }}
              activePage={props.activePage}
              isMobile={false}
              texts={props.texts}
            />
          </div>

          <div className="HeaderTopMenu">
            <TopMenu
              data={{
                currentUser: props.data.currentUser,
              }}
              handlers={{
                openPopupSignIn: props.handlers.openPopupSignIn,
                openPopupSignUp: props.handlers.openPopupSignUp,
              }}
              activePage={props.activePage}
              fixed={isMenuTopFixed}
              texts={props.texts}
            />
          </div>
        </Segment>
      );
    }
    else {
      const isMenuTopFixedForThatPage = true;

      header = (
        <Segment>
            <TopMenu
              data={{
                currentUser: props.data.currentUser,
              }}
              handlers={{
                openPopupSignIn: props.handlers.openPopupSignIn,
                openPopupSignUp: props.handlers.openPopupSignUp,
              }}
              activePage={props.activePage}
              fixed={isMenuTopFixedForThatPage}
              texts={props.texts}
            />
        </Segment>
      );
    }

    return header;
  }

  function renderFooter() {
    return (
      <Segment
        className="Footer"
        inverted={FOOTER_INVERTED}
        vertical={FOOTER_VERTICAL}
      >
        <Footing
          activePage={props.activePage}
          texts={props.texts}
        />
      </Segment>
    );
  }

  function renderHeader() {
    return (
      <Visibility
        className="Visibility"
        once={VISIBILITY_ONCE}
        onBottomPassed={() => (props.activePage === 'home') && showTopFixedMenu()}
        onBottomPassedReverse={() => (props.activePage === 'home') && hideTopFixedMenu()}
      >
        { renderConditionalHeader() }
      </Visibility>
    );
  }

  return (
    <Media className="DesktopContainer" greaterThan="mobile">
      <div className="DesktopContainerParentWrap">
        { renderHeader() }

        <div className="Children">
          { props.children }
        </div>

        { renderFooter() }
      </div>
    </Media>
  );
}

export default DesktopContainer;
