// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import { ChangePasswordPage } from './../pages';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {

      /*
       * UI
       */
      popupOpened: state.ui.popupOpened,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
