// NPM Packages
import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Icon,
  Pagination,
  Table,
} from 'semantic-ui-react';

// Custom Modules
import Loader from './../../Loader';
import Payment from './Payment';

// Styles
import './index.css';

// Constants
const BUTTON_CREATE_PAYMENT_ICON_NAME = 'add circle';
const PAYMENT_PAGINATION_ACTIVE_PAGE_DEFAULT = 1;
const PAYMENT_PAGINATION_COL_SPAN = 7;
const PAYMENT_PAGINATION_FLOATED = 'right';
const PAYMENT_PAGINATION_TOTAL_PAGES_DEFAULT = 1;

function ListPayments(props) {
  // State
  const [ activePayment, setActivePayment ] = useState({});

  // Hooks
  useEffect(() => {
    if (
      props.data.token
      && !props.data.fetching
      && !props.data.fetched
    ) {
      props.handlers.listPayments(props.data.token)
    }
  }, [
    props.data.token,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
  ]);

  useEffect(() => {
    if (activePayment && activePayment.id) {
      setActivePayment(
        props.data.payments.find(payment => payment.id === activePayment.id)
      );
    }
  }, [
    activePayment,
    props.data.payments,
  ]);

  // Handlers
  function handleChangePagination(event, { activePage }) {
    const pagination = { activePage };

    props.handlers.listPayments(props.data.token, pagination)
  }

  // Renderers
  function renderCreateActionButton(user) {
    let result;

    if (!user.isAdmin) {
      result = renderCreatePaymentButton(
        props.handlers.openPopupCreatePayment
      );
    }

    return result;
  }

  function renderCreatePaymentButton(openPopupCreatePayment) {
    return (
      <Button
        onClick={props.handlers.openPopupCreatePayment}
        primary
      >
      <Icon name={BUTTON_CREATE_PAYMENT_ICON_NAME} />
        { props.texts['PAYMENTS_LIST_BUTTON_CREATE_PAYMENT_LABEL'] }
      </Button>
    );
  }

  function renderLoader() {
    return (
      <Loader texts={props.texts} />
    );
  }

  function renderLoaderOrPayments() {
    let result;

    if (
      props.data.payments
      && Object.keys(props.data.payments).length > 0
    ) {
      result = renderPayments(props.data.payments);
    }
    else {
      result = renderLoader();
    }

    return result;
  }

  function renderPayment(payment) {
    return (
      <Payment
        data={{
          payment,
        }}
        key={payment.id}
        texts={props.texts}
      />
    );
  }

  function renderPayments(payments) {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              { props.texts['PAYMENTS_LIST_PAYMENT_NONCE_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['PAYMENTS_LIST_PAYMENT_PRICE_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['PAYMENTS_LIST_PAYMENT_AMOUNT_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['PAYMENTS_LIST_PAYMENT_ADDRESS_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['PAYMENTS_LIST_PAYMENT_IS_RECEIVED_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['PAYMENTS_LIST_PAYMENT_IS_CONFIRMED_LABEL'] }
            </Table.HeaderCell>
            <Table.HeaderCell>
              { props.texts['PAYMENTS_LIST_PAYMENT_DATE_LABEL'] }
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          { payments && payments.map(payment => renderPayment(payment)) }
        </Table.Body>

        <Table.Footer>
          {
            props.data.payments
            && renderPaymentsPagination(props.data.payments)
          }
        </Table.Footer>
      </Table>
    );
  }

  function renderPaymentsPagination(payments) {
    const activePage = (
      props.data.pagination
      && props.data.pagination.activePage
    ) || PAYMENT_PAGINATION_ACTIVE_PAGE_DEFAULT;
    const totalPages = (
      props.data.pagination
      && props.data.pagination.totalPages
    ) || PAYMENT_PAGINATION_TOTAL_PAGES_DEFAULT;

    return (
      <Table.Row>
        <Table.HeaderCell colSpan={PAYMENT_PAGINATION_COL_SPAN}>
          <Pagination
            activePage={activePage}
            floated={PAYMENT_PAGINATION_FLOATED}
            onPageChange={handleChangePagination}
            totalPages={totalPages}
          />
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  return (
    <div className="ListPayments">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            {
              props.data.currentUser
              && renderCreateActionButton(props.data.currentUser)
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Container className="LoaderOrPayments">
              { renderLoaderOrPayments() }
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ListPayments;
