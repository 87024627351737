// Custom Modules
import Account from './../components/Account';
import Layout from './../components/Layout';

function AccountPage(props) {

  // Guard (Current User)
  if (props.data && props.data.currentUser && !props.data.currentUser.id) {
    window.location.replace('/');
    return;
  }

  return (
    <div className="AccountPage">
      <Layout>
        <Account
          data={{
            token: props.data.token,
          }}
          handlers={{
            openPopupUpdateSessionMessengerId: props.handlers.
              openPopupUpdateSessionMessengerId,
          }}
          texts={props.texts}
        />
      </Layout>
    </div>
  );
}

export default AccountPage;
