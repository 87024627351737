// NPM Packages
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Dropdown,
  Label,
  Icon,
  Menu,
} from 'semantic-ui-react'

// Custom Modules
import { SignOut } from './../../../../containers';

// Constants
const LINK_ACCOUNT_AS = 'a';
const LINK_ACCOUNT_ID = 'account';
const LINK_ACCOUNT_LINK = 'true'; // Dropdown.Item -> 'true'
const LINK_ACCOUNT_LINK_TO = '/account';
const TOP_MENU_BUTTONS_POSTIONS = 'right';
const TOP_MENU_BUTTON_SIGN_IN_COLOR = 'grey';
const TOP_MENU_BUTTON_SIGN_IN_INVERTED = false;
const TOP_MENU_BUTTON_SIGN_UP_COLOR = 'purple';
const TOP_MENU_BUTTON_SIGN_UP_INVERTED = false;
const TOP_MENU_INVERTED = true;
const TOP_MENU_LEFT_LINKS_SIDEBAR_ICON_NAME = 'sidebar';
const TOP_MENU_POINTING = true;
const TOP_MENU_SECONDARY = true;
const TOP_MENU_SIZE = 'large';

function TopMenu(props) {
  // Constants
  const ADMIN_TYPE_LABEL = {
    color: 'red',
    horizontal: true,
    label: props.texts['ADMIN_TYPE_LABEL'],
    size: 'small',
  };

  const USER_TYPE_LABELS = {
    FREEMIUM: {
      color: 'blue',
      horizontal: true,
      label: props.texts['USER_TYPE_LABEL_FREEMIUM'],
      size: 'small',
    },
    PREMIUM: {
      color: 'purple',
      horizontal: true,
      label: props.texts['USER_TYPE_LABEL_PREMIUM'],
      size: 'small',
    },
    CUSTOM: {
      color: 'violet',
      horizontal: true,
      label: props.texts['USER_TYPE_LABEL_CUSTOM'],
      size: 'small',
    },
  };

  // Hooks
  const navigate = useNavigate();

  // Renderers
  function renderLeftLinks() {
    return (
      <Menu.Item onClick={() => props.handlers.openSidebar()}>
        <Icon name={TOP_MENU_LEFT_LINKS_SIDEBAR_ICON_NAME} />
      </Menu.Item>
    );
  }

  function renderRightButtons() {
    let buttons;

    if (
      props.data
      && props.data.currentUser
      && props.data.currentUser.id
      && props.data.currentUser.isAdmin
    ) {
      // Admin
      buttons = (
        <>
          <span className="CurrentUserEmail">
            <Label
              color={ADMIN_TYPE_LABEL['color']}
              horizontal={
                ADMIN_TYPE_LABEL['horizontal']
              }
              size={ADMIN_TYPE_LABEL['size']}
            >
              { ADMIN_TYPE_LABEL['label'] }
            </Label>
            { renderUserDropdown() }
          </span>
        </>
      );
    }
    else if (
      props.data
      && props.data.currentUser
      && props.data.currentUser.id
      && !props.data.currentUser.isAdmin
    ) {
      // User
      buttons = (
        <>
          <span className="CurrentUserEmail">
            <Label
              color={USER_TYPE_LABELS[props.data.currentUser.type]['color']}
              horizontal={
                USER_TYPE_LABELS[props.data.currentUser.type]['horizontal']
              }
              size={USER_TYPE_LABELS[props.data.currentUser.type]['size']}
            >
              { USER_TYPE_LABELS[props.data.currentUser.type]['label'] }
            </Label>
            { renderUserDropdown() }
          </span>
        </>
      );
    }
    else {
      // Website
      buttons = (
        <>
          <Button
            color={TOP_MENU_BUTTON_SIGN_IN_COLOR}
            inverted={TOP_MENU_BUTTON_SIGN_IN_INVERTED}
            onClick={props.handlers.openPopupSignIn}
          >
            { props.texts['HEADER_TOP_MENU_BUTTON_SIGN_IN_LABEL'] }
          </Button>
          <Button
            className="SignUpButton"
            color={TOP_MENU_BUTTON_SIGN_UP_COLOR}
            inverted={TOP_MENU_BUTTON_SIGN_UP_INVERTED}
            onClick={
              !(process.env.REACT_APP_DISABLE_SIGN_UP === 'true')
              && props.handlers.openPopupSignUp
            }
          >
            { props.texts['HEADER_TOP_MENU_BUTTON_SIGN_UP_LABEL'] }
          </Button>
        </>
      );
    }

    return (
      <Menu.Item position={TOP_MENU_BUTTONS_POSTIONS}>
        { buttons }
      </Menu.Item>
    );
  }

  function renderUserDropdown() {
    return (
      <Dropdown text={props.data.currentUser.email}>
        <Dropdown.Menu>
          <Dropdown.Item
            active={props.activePage === LINK_ACCOUNT_ID}
            as={LINK_ACCOUNT_AS}
            key={LINK_ACCOUNT_ID}
            link={LINK_ACCOUNT_LINK}
            onClick={_ => navigate(LINK_ACCOUNT_LINK_TO)}
            text={props.texts['HEADER_LINK_ACCOUNT_LABEL']}
          />
          <Dropdown.Divider />
          <Dropdown.Item
            text={
              <SignOut texts={props.texts} />
            }
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <Container>
      <Menu
        inverted={TOP_MENU_INVERTED}
        pointing={TOP_MENU_POINTING}
        secondary={TOP_MENU_SECONDARY}
        size={TOP_MENU_SIZE}
      >
        { renderLeftLinks() }

        { renderRightButtons() }
      </Menu>
    </Container>
  );
}

export default TopMenu;
