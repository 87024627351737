// Custom Modules
import Home from './../../components/website/Home';

function HomePage(props) {
  return (
    <div className="HomePage">
      <Home
        activePage={props.activePage}
        texts={props.texts}
      />
    </div>
  );
}

export default HomePage;
