// Custom Modules
import Layout from './../components/Layout';
import ListUsers from './../components/users/ListUsers';

function UsersPage(props) {

  // Guard (Admin)
  if (props.data && props.data.currentUser && !props.data.currentUser.isAdmin) {
    window.location.replace('/');
    return;
  }

  return (
    <div className="UsersPage">
      <Layout>
        <ListUsers
          data={{
            token: props.data.token,
            users: props.data.users,
            pagination: props.data.pagination,
            fetching: props.data.fetching,
            fetched: props.data.fetched,
          }}
          handlers={{
            listUsers: props.handlers.listUsers,
            selectUser: props.handlers.selectUser,
          }}
          texts={props.texts}
        />
      </Layout>
    </div>
  );
}

export default UsersPage;
