// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  /*
   * Users
   */
  deleteUserRequest,

  /*
   * UI
   */
  closePopup,
  openPopup,
} from './../store/actions';
import DeleteUser from './../components/users/DeleteUser';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {
      ...ownProps.data,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Users
       */
      deleteUser: (token, userId) => deleteUserRequest(token, userId, dispatch),

      /*
       * UI
       */
      closePopup: () => closePopup(dispatch),
      openPopup: () => openPopup(dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser);
