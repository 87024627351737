// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  /*
   * Videos
   */
  deleteVideoRequest,

  /*
   * UI
   */
  closePopup,
  openPopup,
} from './../store/actions';
import DeleteVideo from './../components/videos/DeleteVideo';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {
      ...ownProps.data,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * UI
       */
      closePopup: () => closePopup(dispatch),
      openPopup: () => openPopup(dispatch),

      /*
       * Videos
       */
      deleteVideo: (token, videoId) => deleteVideoRequest(token, videoId, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteVideo);
