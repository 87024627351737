// NPM Packages
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  TextArea,
} from 'semantic-ui-react'

// Custom Modules
import Notice from './../../Notice';

// Styles
import './index.css';

// Constants
const FORM_FIELD_INDEX_TYPE = 'number';
const FORM_FIELD_NAME_TYPE = 'text';
const FORM_FIELD_DESCRIPTION_STYLE_MIN_HEIGHT = 100;
const FORM_FIELD_DESCRIPTION_ROWS = 2;
const FORM_FIELD_DESCRIPTION_TYPE = 'text';
const FORM_FIELD_DURATION_TYPE = 'number';
const FORM_FIELD_FILE_ACCEPT = 'video/mp4'
const FORM_FIELD_FILE_TYPE = 'file';
const FORM_FIELD_FILE_ICON_NAME = 'file';
const FORM_BUTTON_CANCEL_ICON_NAME = 'cancel';
const FORM_BUTTON_CREATE_ICON_NAME = 'add circle';
const POPUP_CLOSE_TIMEOUT = process.env.REACT_APP_POPUP_CLOSE_TIMEOUT;

function CreateVideo(props) {
  // State
  const [ video, setVideo ] = useState({
    index: 0,
    name: '',
    description: '',
    duration: 0,
    file: null,
  });

  // Hooks
  useEffect(() => {
    if (!props.data.fetching && props.data.fetched) {
      setTimeout(() => {
        props.handlers.closePopup();
      }, POPUP_CLOSE_TIMEOUT);
    }
  }, [
    props.data,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
  ]);

  // Handlers
  function handleUpdateVideo(key, value) {
    setVideo({
      ...video,
      [key]: value,
    });
  }

  // Helpers
  function isFormComplete() {
    let result = false;

    if (
      video.index
      && video.name
      && video.description
      && video.duration
      && video.file
    ) {
      result = true;
    }

    return result;
  }

  function renderNotice(notice) {
    return (
      <div className="Notice">
        <Notice
          data={{
            notice,
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Modal.Header className="CreateVideo">
        <div className="ModalHeader">
          { props.texts['VIDEOS_CREATE_MODAL_HEADER_LABEL'] }
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>
                { props.texts['VIDEOS_CREATE_FIELD_INDEX_LABEL'] }
              </label>
              <Input
                onChange={event => handleUpdateVideo('index', event.target.value)}
                placeholder={props.texts['VIDEOS_CREATE_FIELD_INDEX_PLACEHOLDER']}
                type={FORM_FIELD_INDEX_TYPE}
                value={video.index}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['VIDEOS_CREATE_FIELD_NAME_LABEL'] }
              </label>
              <Input
                onChange={event => handleUpdateVideo('name', event.target.value)}
                placeholder={props.texts['VIDEOS_CREATE_FIELD_NAME_PLACEHOLDER']}
                type={FORM_FIELD_NAME_TYPE}
                value={video.name}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['VIDEOS_CREATE_FIELD_DESCRIPTION_LABEL'] }
              </label>
              <TextArea
                onChange={event => handleUpdateVideo('description', event.target.value)}
                placeholder={props.texts['VIDEOS_CREATE_FIELD_DESCRIPTION_PLACEHOLDER']}
                row={FORM_FIELD_DESCRIPTION_ROWS}
                style={{ FORM_FIELD_DESCRIPTION_STYLE_MIN_HEIGHT }}
                type={FORM_FIELD_DESCRIPTION_TYPE}
                value={video.description}
              />
            </Form.Field>
            <Form.Field>
              <label>
                { props.texts['VIDEOS_CREATE_FIELD_DURATION_LABEL'] }
              </label>
              <Input
                onChange={event => handleUpdateVideo('duration', Number(event.target.value))}
                placeholder={props.texts['VIDEOS_CREATE_FIELD_DURATION_PLACEHOLDER']}
                type={FORM_FIELD_DURATION_TYPE}
                value={video.duration}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <Icon name={FORM_FIELD_FILE_ICON_NAME} />
                { props.texts['VIDEOS_CREATE_FIELD_FILE_LABEL'] }
              </label>
              <Input
                accept={FORM_FIELD_FILE_ACCEPT}
                onChange={event => handleUpdateVideo('file', event.target.files[0])}
                placeholder={props.texts['VIDEOS_CREATE_FIELD_FILE_PLACEHOLDER']}
                type={FORM_FIELD_FILE_TYPE}
              />
            </Form.Field>
        </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={props.data.fetching}
          onClick={props.handlers.closePopup}
          secondary
        >
          <Icon name={FORM_BUTTON_CANCEL_ICON_NAME} />
          { props.texts['VIDEOS_CREATE_BUTTON_CANCEL_LABEL'] }
        </Button>
        <Button
          disabled={!isFormComplete() || props.data.fetching}
          loading={props.data.fetching}
          onClick={() => props.handlers.createVideo(props.data.token, video)}
          primary
        >
        <Icon name={FORM_BUTTON_CREATE_ICON_NAME} />
          { props.texts['VIDEOS_CREATE_BUTTON_CREATE_LABEL'] }
        </Button>
      </Modal.Actions>
      {
        props.data.notice
        && renderNotice(props.data.notice)
      }
    </>
  );
}

export default CreateVideo;
