// NPM Packages
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Grid,
} from 'semantic-ui-react';

// Custom Modules
import Plans from './Plans';

// Styles
import './index.css';

// Constants
const MARKDOWN_FILE_NAME = 'plansAndPricing.md';

function PlansAndPricing(props) {
  // State
  const [ markdown, setMarkdown ] = useState('');

  // Hooks
  useEffect(() => {
    import('./../../../markdowns/' + MARKDOWN_FILE_NAME)
      .then(module => {
        fetch(module.default)
          .then(res => res.text())
          .then(text => setMarkdown(text));
      });
  }, []);

  return (
    <div className="PlansAndPricing">
      <Grid
        container
        stackable
        verticalAlign="middle"
      >
        <Grid.Row>
          <Plans
            data={props.data}
            handlers={props.handlers}
            activePage={props.activePage}
            texts={props.texts}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <ReactMarkdown children={markdown} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default PlansAndPricing;
