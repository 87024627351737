// NPM Packages
import {
  Button,
  Icon,
} from 'semantic-ui-react'

// Styles
import './index.css';

// Constants
const BUTTON_SIGN_OUT_ICON_NAME = 'sign-out';

function SignOut(props) {
  return (
    <Button
      className="SignOut"
      inverted={props.data.inverted}
      loading={props.data.fetching}
      onClick={() => props.handlers.signOut(props.data.token)}
    >
      <Icon name={BUTTON_SIGN_OUT_ICON_NAME} />
      { props.texts['AUTH_BUTTON_SIGN_OUT_LABEL'] }
    </Button>
  );
}

export default SignOut;
