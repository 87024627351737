// Custom Modules
import ConfirmEmailPageComponent from './../components/auth/ConfirmEmailPage';
import Layout from './../components/Layout';

function ConfirmEmailPage(props) {
  return (
    <div className="ConfirmEmailPage">
      <Layout>
        <ConfirmEmailPageComponent
          data={{
            popupOpened: props.data.popupOpened,
          }}
          handlers={{
            openPopupConfirmEmail: props.handlers.openPopupConfirmEmail,
          }}
          texts={props.texts}
          textsLoaded={props.textsLoaded}
        />
      </Layout>
    </div>
  );
}

export default ConfirmEmailPage;
