// Custom Modules
import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';

// Styles
import './index.css';

function ResponsiveContainer(props) {
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  return (
    <>
      <DesktopContainer
        data={{
          currentUser: props.data.currentUser,
        }}
        handlers={{
          openPopupGetStarted: props.handlers.openPopupGetStarted,
          openPopupSignIn: props.handlers.openPopupSignIn,
          openPopupSignUp: props.handlers.openPopupSignUp,
        }}
        activePage={props.activePage}
        texts={props.texts}
        Media={props.Media}
      >
        { props.children }
      </DesktopContainer>
      <MobileContainer
        data={{
          currentUser: props.data.currentUser,
        }}
        handlers={{
          openPopupGetStarted: props.handlers.openPopupGetStarted,
          openPopupSignIn: props.handlers.openPopupSignIn,
          openPopupSignUp: props.handlers.openPopupSignUp,
        }}
        activePage={props.activePage}
        texts={props.texts}
        Media={props.Media}
      >
        { props.children }
      </MobileContainer>
    </>
  );
}

export default ResponsiveContainer;
