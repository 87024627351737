// NPM Packages
import { connect } from 'react-redux';

// Custom Modules
import {
  listUsersRequest,
  selectUser,
} from './../store/actions';
import { UsersPage } from './../pages';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,

    data: {
      ...ownProps.data,

      /*
       * Auth
       */
      token: state.auth.token,

      /*
       * User
       */
      currentUser: state.user.current,

      /*
       * Users
       */
      users: state.users.ids.map(id => state.users.items[id]),
      pagination: state.users.pagination,
      fetching: state.users.fetching,
      fetched: state.users.fetched,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,

    handlers: {
      ...ownProps.handlers,

      /*
       * Users
       */
      listUsers: (token, pagination = {}) => {
        return listUsersRequest(token, pagination, dispatch);
      },
      selectUser: (userId) => selectUser(userId, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
