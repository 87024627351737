// NPM Packages
import axios from 'axios';

// Custom Modules
import {
  // CRUD Operations
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  LIST_PAYMENTS_REQUEST,
  LIST_PAYMENTS_SUCCESS,
  LIST_PAYMENTS_FAILURE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
} from './../actionTypes';

// CRUD Operations
function createPaymentRequest(token, payment, dispatch) {
  dispatch({
    type: CREATE_PAYMENT_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/payments';
  axios.post(url, {
    price: payment.price,
  } , {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        createPaymentSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        createPaymentFailure(error)
      );
    });
}

function createPaymentSuccess(data) {
  return {
    payload: data,
    type: CREATE_PAYMENT_SUCCESS,
  };
}

function createPaymentFailure(data) {
  return {
    payload: data,
    type: CREATE_PAYMENT_FAILURE,
  };
}

function listPaymentsRequest(token, pagination, dispatch) {
  dispatch({
    type: LIST_PAYMENTS_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/payments';
  axios.get(url, {
    headers: {
      'x-auth': token,
    },
    params: {
      ...pagination,
    }
  })
    .then(response => {
      dispatch(
        listPaymentsSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        listPaymentsFailure(error)
      );
    });
}

function listPaymentsSuccess(data) {
  return {
    payload: data,
    type: LIST_PAYMENTS_SUCCESS,
  };
}

function listPaymentsFailure(data) {
  return {
    payload: data,
    type: LIST_PAYMENTS_FAILURE,
  };
}

function updatePaymentRequest(token, payment, dispatch) {
  dispatch({
    type: UPDATE_PAYMENT_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/payments/' + payment.id;
  axios.patch(url, {
    isListenedTo: payment.isListenedTo,
  }, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        updatePaymentSuccess(response.data)
      );
    })
    .catch(error => {
      dispatch(
        updatePaymentFailure(error.response.data)
      );
    });
}

function updatePaymentSuccess(data) {
  return {
    payload: data,
    type: UPDATE_PAYMENT_SUCCESS,
  };
}

function updatePaymentFailure(data) {
  return {
    payload: data,
    type: UPDATE_PAYMENT_FAILURE,
  };
}

export {
  // CRUD Operations
  createPaymentRequest,
  createPaymentSuccess,
  createPaymentFailure,
  listPaymentsRequest,
  listPaymentsSuccess,
  listPaymentsFailure,
  updatePaymentRequest,
  updatePaymentSuccess,
  updatePaymentFailure,
};
