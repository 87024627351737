// Custom Modules
import BookMe from './../../components/website/BookMe';
import WebsiteLayout from './../../components/WebsiteLayout';

function BookMePage(props) {
  return (
    <div className="BookMePage">
      <WebsiteLayout>
        <BookMe
          activePage={props.activePage}
          texts={props.texts}
        />
      </WebsiteLayout>
    </div>
  );
}

export default BookMePage;
