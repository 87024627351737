// NPM Packages
import axios from 'axios';

// Custom Modules
import {
  // Auth Operations
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
} from './../actionTypes';

// Auth Operations
function signUpRequest(user, dispatch) {
  dispatch({
    type: SIGN_UP_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/auth/sign-up';
  axios.post(url, {
    ...user,
  })
    .then(response => {
      dispatch(
        signUpSuccess({
          notice: response.data.notice,
          user: response.data.user,
        })
      );
    })
    .catch(error => {
      dispatch(signUpFailure(error.response.data));
    });
}

function signUpSuccess(data) {
  return {
    payload: data,
    type: SIGN_UP_SUCCESS,
  };
}

function signUpFailure(data) {
  return {
    payload: data,
    type: SIGN_UP_FAILURE,
  };
}

function confirmEmailRequest(token, dispatch) {
  dispatch({
    type: CONFIRM_EMAIL_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/auth/confirm-email';
  axios.post(url, {
    token,
  })
    .then(response => {
      dispatch(
        confirmEmailSuccess({
          notice: response.data.notice,
          user: response.data.user,
        })
      );
    })
    .catch(error => {
      dispatch(confirmEmailFailure(error.response.data));
    });
}

function confirmEmailSuccess(data) {
  return {
    payload: data,
    type: CONFIRM_EMAIL_SUCCESS,
  };
}

function confirmEmailFailure(data) {
  return {
    payload: data,
    type: CONFIRM_EMAIL_FAILURE,
  };
}

function signInRequest(user, dispatch) {
  dispatch({
    type: SIGN_IN_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/auth/sign-in';
  axios.post(url, {
    ...user,
  })
    .then(response => {
      dispatch(
        signInSuccess({
          token: response.data.token,
          user: response.data.user,
        })
      );
    })
    .catch(error => {
      dispatch(
        signInFailure(error.response.data)
      )
    });
}

function signInSuccess(data) {
  return {
    payload: data,
    type: SIGN_IN_SUCCESS,
  };
}

function signInFailure(data) {
  return {
    payload: data,
    type: SIGN_IN_FAILURE,
  };
}

function resetPasswordRequest(user, dispatch) {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/auth/reset-password';
  axios.post(url, {
    ...user,
  })
    .then(response => {
      dispatch(
        resetPasswordSuccess({
          notice: response.data.notice,
          user: response.data.user,
        })
      );
    })
    .catch(error => {
      dispatch(resetPasswordFailure(error.response.data));
    });
}

function resetPasswordSuccess(data) {
  return {
    payload: data,
    type: RESET_PASSWORD_SUCCESS,
  };
}

function resetPasswordFailure(data) {
  return {
    payload: data,
    type: RESET_PASSWORD_FAILURE,
  };
}

function changePasswordRequest(user, token, dispatch) {
  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/auth/change-password';
  axios.post(url, {
    ...user,
    token,
  })
    .then(response => {
      dispatch(
        changePasswordSuccess({
          notice: response.data.notice,
          user: response.data.user,
        })
      );
    })
    .catch(error => {
      dispatch(changePasswordFailure(error.response.data));
    });
}

function changePasswordSuccess(data) {
  return {
    payload: data,
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

function changePasswordFailure(data) {
  return {
    payload: data,
    type: CHANGE_PASSWORD_FAILURE,
  };
}

function signOutRequest(token, dispatch) {
  dispatch({
    type: SIGN_OUT_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/auth/sign-out';
  axios.get(url, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        signOutSuccess({})
      );
    })
    .catch(error => {
      dispatch(
        signOutFailure(error)
      );
    });
}

function signOutSuccess(data) {
  return {
    payload: data,
    type: SIGN_OUT_SUCCESS,
  };
}

function signOutFailure(data) {
  return {
    payload: data,
    type: SIGN_OUT_FAILURE,
  };
}

export {
  // Auth Operations
  signUpRequest,
  signUpSuccess,
  signUpFailure,
  confirmEmailRequest,
  confirmEmailSuccess,
  confirmEmailFailure,
  signInRequest,
  signInSuccess,
  signInFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  signOutRequest,
  signOutSuccess,
  signOutFailure,
};
