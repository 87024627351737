// Custom Modules
import Layout from './../components/Layout';
import ReadVideo from './../components/videos/ReadVideo';

function VideoPage(props) {

  // Guard (Current User)
  if (props.data && props.data.currentUser && !props.data.currentUser.id) {
    window.location.replace('/');
    return;
  }

  return (
    <div className="VideoPage">
      <Layout>
        <ReadVideo
          data={{
            currentUser: props.data.currentUser,
            token: props.data.token,
            activeVideo: props.data.activeVideo,
            fetching: props.data.fetching,
            fetched: props.data.fetched,
          }}
          handlers={{
            readVideo: props.handlers.readVideo,
          }}
          texts={props.texts}
        />
      </Layout>
    </div>
  );
}

export default VideoPage;
