// NPM Packages
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Icon,
  Image,
  Label,
} from 'semantic-ui-react';
import moment from 'moment';

// Custom Modules
import { formatDuration } from './../../../../helpers';
import { DeleteVideo } from './../../../../containers';

// Styles
import './index.css';

// Constants
const BUTTON_UPDATE_VIDEO_ICON = true;
const BUTTON_UPDATE_VIDEO_ICON_NAME = 'edit';
const BUTTON_UPDATE_VIDEO_SIZE = 'mini';
const IMAGE_UI = false;
const IMAGE_WRAPPED = true;

function Video(props) {
  // Constants
  const VIDEO_TYPE_LABELS = {
    FREEMIUM: {
      color: 'blue',
      horizontal: true,
      label: props.texts['VIDEO_TYPE_LABEL_FREEMIUM'],
      size: 'small',
    },
    PREMIUM: {
      color: 'purple',
      horizontal: true,
      label: props.texts['VIDEO_TYPE_LABEL_PREMIUM'],
      size: 'small',
    },
    CUSTOM: {
      color: 'violet',
      horizontal: true,
      label: props.texts['VIDEO_TYPE_LABEL_CUSTOM'],
      size: 'small',
    },
  };

  // Navigate
  const navigate = useNavigate();

  // Handlers
  function handleClickVideo(video) {
    return navigate('/videos/' + video.id);
  }

  // Renderers
  function renderVideoActions(video) {
    return (
      <Card.Content className="actions" extra>
        <Button
          icon={BUTTON_UPDATE_VIDEO_ICON}
          onClick={() => props.handlers.openPopupUpdateVideo(video)}
          secondary
          size={BUTTON_UPDATE_VIDEO_SIZE}
        >
          <Icon name={BUTTON_UPDATE_VIDEO_ICON_NAME} />
        </Button>
        <DeleteVideo
          data={{
            fetching: props.data.fetching,
            token: props.data.token,
            videoId: video.id,
          }}
          texts={props.texts}
        />
      </Card.Content>
    );
  }

  function renderVideoCardMetaCreated(video) {
    return props.texts['VIDEOS_LIST_VIDEO_CREATED_AT_LABEL']
      + ' '
      + moment(video.createdAt).fromNow();
  }

  function renderVideoCardMetaDuration(video) {
    return props.texts['VIDEOS_LIST_VIDEO_DURATION_LABEL']
      + ' '
      + formatDuration(video.duration);
  }

  function renderVideoCardMetaType(video) {
    return (
      <Label
        color={VIDEO_TYPE_LABELS[video.type]['color']}
        horizontal={
          VIDEO_TYPE_LABELS[video.type]['horizontal']
        }
        size={VIDEO_TYPE_LABELS[video.type]['size']}
      >
        { VIDEO_TYPE_LABELS[video.type]['label'] }
      </Label>
    );
  }

  function renderVideoCardMetaUpdated(video) {
    return props.texts['VIDEOS_LIST_VIDEO_UPDATED_LABEL']
      + ' '
      + moment(video.updatedAt).fromNow();
  }

  function renderVideoSrc(video) {
    return process.env.REACT_APP_API_GTWY_URL
      + '/videos/' + video.id + '/thumbnail?token=' + props.data.token;
  }

  return (
    <Card className="Video">
      <Image
        alt={props.data.video.name}
        onClick={_ => handleClickVideo(props.data.video)}
        src={renderVideoSrc(props.data.video)}
        ui={IMAGE_UI}
        wrapped={IMAGE_WRAPPED}
      />
      <Card.Content>
        <Card.Header>{ props.data.video.name }</Card.Header>
        <Card.Meta>
          { renderVideoCardMetaType(props.data.video) }
        </Card.Meta>
        <Card.Meta>
          { renderVideoCardMetaCreated(props.data.video) }
        </Card.Meta>
        <Card.Meta>
          { renderVideoCardMetaUpdated(props.data.video) }
        </Card.Meta>
        <Card.Meta>
          { renderVideoCardMetaDuration(props.data.video) }
        </Card.Meta>
        <Card.Description>
          { props.data.video.description }
        </Card.Description>
      </Card.Content>
      {
        props.data
        && props.data.currentUser
        && props.data.currentUser.isAdmin
        && renderVideoActions(props.data.video)
      }
    </Card>
  );
}

export default Video;
