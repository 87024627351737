// NPM Packages
import { useEffect, useState } from 'react';
import {
  Button,
  CardGroup,
  Container,
  Grid,
  Icon,
  Pagination,
} from 'semantic-ui-react';

// Custom Modules
import Loader from './../../Loader';
import Video from './Video';

// Styles
import './index.css';

// Constants
const BUTTON_CREATE_PAYMENT_ICON_NAME = 'add circle';
const BUTTON_CREATE_VIDEO_ICON_NAME = 'add circle';
const VIDEOS_PAGINATION_ACTIVE_PAGE_DEFAULT = 1;
const VIDEOS_PAGINATION_TOTAL_PAGES_DEFAULT = 1;

function ListVideos(props) {
  // State
  const [ activeVideo, setActiveVideo ] = useState({});

  // Hooks
  useEffect(() => {
    if (
      props.data.token
      && !props.data.fetching
      && !props.data.fetched
    ) {
      props.handlers.listVideos(props.data.token)
    }
  }, [
    props.data.token,
    props.data.fetching,
    props.data.fetched,
    props.handlers,
  ]);

  useEffect(() => {
    if (activeVideo && activeVideo.id) {
      setActiveVideo(
        props.data.videos.find(video => video.id === activeVideo.id)
      );
    }
  }, [
    activeVideo,
    props.data.videos,
  ]);

  // Handlers
  function handleChangePagination(event, { activePage }) {
    const pagination = { activePage };

    props.handlers.listVideos(props.data.token, pagination)
  }

  // Renderers
  function renderCreateActionButton(user) {
    let result;

    if (user.isAdmin) {
      result = renderCreateVideoButton(
        props.handlers.openPopupCreateVideo
      );
    }
    else {
      if (user.isSubscriptionActive) {
        result = null;
      }
      else {
        result = renderCreatePaymentButton(
          props.handlers.openPopupCreatePayment
        );
      }
    }

    return result;
  }

  function renderCreatePaymentButton(openPopupCreatePayment) {
    return (
      <Button
        onClick={props.handlers.openPopupCreatePayment}
        primary
      >
        <Icon name={BUTTON_CREATE_PAYMENT_ICON_NAME} />
        { props.texts['VIDEOS_LIST_BUTTON_CREATE_PAYMENT_LABEL'] }
      </Button>
    );
  }

  function renderCreateVideoButton(openPopupCreateVideo) {
    return (
      <Button
        onClick={props.handlers.openPopupCreateVideo}
        primary
      >
        <Icon name={BUTTON_CREATE_VIDEO_ICON_NAME} />
        { props.texts['VIDEOS_LIST_BUTTON_CREATE_VIDEO_LABEL'] }
      </Button>
    );
  }

  function renderLoader() {
    return (
      <Loader texts={props.texts} />
    );
  }

  function renderLoaderOrVideos() {
    let result;

    if (
      props.data.videos
      && Object.keys(props.data.videos).length > 0
    ) {
      result = renderVideos(props.data.videos);
    }
    else {
      result = renderLoader();
    }

    return result;
  }

  function renderVideo(video) {
    return (
      <Video
        data={{
          currentUser: props.data.currentUser,
          fetching: props.data.fetching,
          token: props.data.token,
          video,
        }}
        handlers={{
          openPopupUpdateVideo: props.handlers.openPopupUpdateVideo,
        }}
        key={video.id}
        texts={props.texts}
      />
    );
  }

  function renderVideos(videos) {
    return (
      <CardGroup stackable textAlign="center">
        { videos && videos.map(renderVideo) }
      </CardGroup>
    );
  }

  function renderVideosPagination(videos) {
    const activePage = (
      props.data.pagination
      && props.data.pagination.activePage
    ) || VIDEOS_PAGINATION_ACTIVE_PAGE_DEFAULT;
    const totalPages = (
      props.data.pagination
      && props.data.pagination.totalPages
    ) || VIDEOS_PAGINATION_TOTAL_PAGES_DEFAULT;

    return (
      <div className="Pagination">
        <Pagination
          activePage={activePage}
          onPageChange={handleChangePagination}
          totalPages={totalPages}
        />
      </div>
    );
  }

  return (
    <div className="ListVideos">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            {
              props.data.currentUser
              && renderCreateActionButton(props.data.currentUser)
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Container className="LoaderOrVideos">
              { renderLoaderOrVideos() }
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {
              props.data.videos
              && renderVideosPagination(props.data.videos)
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ListVideos;
