// NPM Packages
import axios from 'axios';

// Custom Modules
import {
  READ_USER_SESSION_MESSENGER_ID_REQUEST,
  READ_USER_SESSION_MESSENGER_ID_SUCCESS,
  READ_USER_SESSION_MESSENGER_ID_FAILURE,
  UPDATE_USER_SESSION_MESSENGER_ID_REQUEST,
  UPDATE_USER_SESSION_MESSENGER_ID_SUCCESS,
  UPDATE_USER_SESSION_MESSENGER_ID_FAILURE,
} from './../actionTypes';

function readUserSessionMessengerIdRequest(token, dispatch) {
  dispatch({
    type: READ_USER_SESSION_MESSENGER_ID_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/user/session-messenger-id';
  axios.get(url, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        readUserSessionMessengerIdSuccess({
          sessionMessengerId: response.data.sessionMessengerId,
        })
      );
    })
    .catch(error => {
      dispatch(
        readUserSessionMessengerIdFailure(error.response.data)
      );
    });
}

function readUserSessionMessengerIdSuccess(data) {
  return {
    payload: data,
    type: READ_USER_SESSION_MESSENGER_ID_SUCCESS,
  };
}

function readUserSessionMessengerIdFailure(data) {
  return {
    payload: data,
    type: READ_USER_SESSION_MESSENGER_ID_FAILURE,
  };
}

function updateUserSessionMessengerIdRequest(token, sessionMessengerId, dispatch) {
  dispatch({
    type: UPDATE_USER_SESSION_MESSENGER_ID_REQUEST,
  });

  const url = process.env.REACT_APP_API_GTWY_URL + '/user/session-messenger-id';
  axios.patch(url, {
    sessionMessengerId,
  }, {
    headers: {
      'x-auth': token,
    },
  })
    .then(response => {
      dispatch(
        updateUserSessionMessengerIdSuccess({
          notice: response.data.notice,
          sessionMessengerId: response.data.sessionMessengerId,
        })
      );
    })
    .catch(error => {
      dispatch(
        updateUserSessionMessengerIdFailure(error.response.data)
      );
    });
}

function updateUserSessionMessengerIdSuccess(data) {
  return {
    payload: data,
    type: UPDATE_USER_SESSION_MESSENGER_ID_SUCCESS,
  };
}

function updateUserSessionMessengerIdFailure(data) {
  return {
    payload: data,
    type: UPDATE_USER_SESSION_MESSENGER_ID_FAILURE,
  };
}

export {
  readUserSessionMessengerIdRequest,
  readUserSessionMessengerIdSuccess,
  readUserSessionMessengerIdFailure,
  updateUserSessionMessengerIdRequest,
  updateUserSessionMessengerIdSuccess,
  updateUserSessionMessengerIdFailure,
};
